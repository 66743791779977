import "babel-polyfill";
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import App from "./App";

const ApplicationStore = configureStore();

ReactDOM.render(
    <Provider store={ApplicationStore}>
        <App />
    </Provider>,
    document.getElementById("root"));
