import { initialize, addTranslationForLanguage } from "react-localize-redux";

export default (store) => {

    const languages = [
        { name: "English", code: "en" },
        { name: "German", code: "de" },
        { name: "Spanish", code: "es" },
        { name: "French", code: "fr" },
        { name: "Japanese", code: "ja" },
        { name: "Dutch", code: "nl" },
        { name: "Chinese (Simplified / China)", code: "zh-CN" }
    ];

    store.dispatch(initialize(languages));

    store.dispatch(addTranslationForLanguage(require("../resources/resources.json"), "en"));
    store.dispatch(addTranslationForLanguage(require("../resources/resources.de.json"), "de"));
    store.dispatch(addTranslationForLanguage(require("../resources/resources.es.json"), "es"));
    store.dispatch(addTranslationForLanguage(require("../resources/resources.fr.json"), "fr"));
    store.dispatch(addTranslationForLanguage(require("../resources/resources.ja.json"), "ja"));
    store.dispatch(addTranslationForLanguage(require("../resources/resources.nl.json"), "nl"));
    store.dispatch(addTranslationForLanguage(require("../resources/resources.zh-CN.json"), "zh-CN"));
}
