import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./index.css";

import activityIndicatorImage from "./asterisk-sdl-green.svg";

class ActivityIndicator extends PureComponent {

    render() {

        const {
            duration,
            spin,
        } = this.props;

        const imageProps = {
            src: activityIndicatorImage,
        };

        if (spin !== "none")
        {
            imageProps.className = "animated";
            imageProps.style = {
                "animationName": `spin-${spin}`,
                "animationDuration": `${duration}ms`,
            };
        }

        let children = null;
        if (this.props.children !== undefined)
        {
            children = (<div>{this.props.children}</div>);
        }

        return (
            <div className="activity-indicator">
                <img {...imageProps} alt=""/>
                {children}
            </div>
        );
    }
}

ActivityIndicator.propTypes = {

    spin: PropTypes.oneOf(["none", "left", "right"]).isRequired,
    duration: PropTypes.number.isRequired,
};

ActivityIndicator.defaultProps = {

    spin: "right",
    duration: 1500,
};

export default ActivityIndicator
