import { Button, Icon, Table } from 'antd';
import "font-awesome/css/font-awesome.css";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isUnable } from "../../../../components/Can";
import { actions as extensionManagerActions, selectors as extensionManagerSelectors } from "../../../ExtensionManager";
import { actions as popupManagerActions } from "../../../PopupManager";
import { ACTIONS, selectors as accountSelectors } from '../../../PrivateRoute';


class ExtensionsGrid extends PureComponent {

    constructor() {
        super();
        this.getGridProps = this.getGridProps.bind(this);
        this.onClickDeleteExtStatus = this.onClickDeleteExtStatus.bind(this);
    }

    refreshExtensions = () => {
        const { getAddon } = this.props.extensionManagerActions;
        getAddon({ id: this.props.addonId });
    }

    getGridProps() {

        const { addonId, extensionManager, translate } = this.props;
        const extensions = extensionManagerSelectors.getAddonExtensionsById(extensionManager, addonId);

        const gridProps = {
            data: extensions,
            columns: [
                {
                    title: translate("addonView.name"),
                    dataIndex: "name",
                    key: "name",
                    sorter: (a, b) => (a.name.localeCompare(b.name))
                },
                {
                    title: translate("addonView.type"),
                    dataIndex: "type",
                    key: "type",
                    sorter: (a, b) => (a.type.localeCompare(b.type))
                },
                {
                    title: translate("manageView.addOnsList.status"),
                    dataIndex: "status",
                    key: "status",
                    sorter: (a, b) => (a.status.localeCompare(b.status)),
                    render: stat => {
                        let modifier, icon, text, theme;

                        switch (stat) {
                            case 'Pending':
                                modifier = 'pending';
                                icon = 'loading';
                                text = translate('manageView.addOnsList.pending');
                                break;
                            case 'Success':
                                modifier = 'success';
                                icon = 'check-circle';
                                text = translate('manageView.addOnsList.success');
                                break;
                            case 'Fail':
                                modifier = 'error';
                                icon = 'exclamation-circle';
                                text = translate('manageView.addOnsList.fail');
                                theme = 'filled';
                                break;
                            case 'WaitingConfiguration':
                                modifier = 'error';
                                icon = 'exclamation-circle';
                                text = translate('manageView.addOnsList.waitingConfiguration');
                                theme = 'filled';
                                break;
                            default:
                                break;

                        }

                        return (
                            <div className={`status-tag status-tag--${modifier}`}>
                                <span className="status-tag__icon">
                                    <Icon type={icon} theme={theme} />
                                </span>
                                <span className="status-tag__text">{text}</span>

                            </div>
                        );
                    }
                }
            ],
            isLoading: extensionManagerSelectors.isAddonLoading(extensionManager),
            isRowExpandable: (row) => row.errors.length > 0,
            renderExpandedData: (row) => row.errors.map(error => (<div className="extension-error">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <p>{error}</p>
            </div>)),
        };

        return gridProps;
    }

    CustomExpandIcon(props) {
        let text;

        if (props.expanded) {
            text = <Icon type="down" />;
        } else {
            text = <Icon type="right" />;
        }

        if (props.record.statusReports.length || props.record.disabledClients.length) {
            return (
                <button className="table__expand-row-icon"
                    onClick={e => props.onExpand(props.record, e)}
                    style={{ cursor: 'pointer' }}
                >{text}</button>
            );
        }

        return null;
    }

    onClickDeleteExtStatus(id, clientId) {
        const { registerPopup } = this.props.popupManagerActions;
        const { translate, extensionManagerActions, addonId } = this.props;
        const addon = extensionManagerSelectors.getAddonById(this.props.extensionManager, addonId);

        registerPopup({
            icon: "fa-question",
            danger: true,
            title: translate("addonView.extensions.confirmTitle", { envId: clientId }),
            message: translate("addonView.extensions.confirmMessage"),
            actionLabel: translate("addonView.extensions.confirmYes"),
            cancelLabel: translate("addonView.extensions.confirmNo"),
            actionHandler: () => extensionManagerActions.deleteExtensionStatus({
                addonId,
                extId: id,
                clientId,
                successMessage: translate("addonView.extensions.statusResetSuccess"),
                failMessage: translate("addonView.extensions.statusResetFail", { extName: addon.name, envId: clientId, }),
            }),
        });

    }

    render() {
        const { columns, data, isLoading } = this.getGridProps();
        const { translate, roles } = this.props;

        return (<>
            <Button
                disabled={isUnable(roles, ACTIONS.status.reset)}
                className="content-provider__btn-refresh btn"
                icon="reload"
                type="primary"
                onClick={this.refreshExtensions}
            >{translate("addonView.actionBar.refresh")}</Button>

            <Table
                rowKey={record => (`${record.id}-${record.statusReports.length}`)}
                expandIcon={this.CustomExpandIcon}
                className="content-provider__table table"
                columns={columns}
                dataSource={data}
                loading={isLoading}
                size="small"
                pagination={false}
                expandedRowRender={record => {
                    let messages = record.statusReports.map(report => {
                        let icon, className, tooltip, message;

                        switch (report.status) {

                            case 'Success':
                                className = 'success';
                                icon = 'check-circle';
                                tooltip = translate('manageView.addOnsList.success');
                                message = translate('addonView.extensions.statusFormat', {
                                    message: report.message || '',
                                    environment: report.environment,
                                    clientId: report.clientIdentity,
                                    process: report.process,
                                });
                                break;

                            case 'Fail':
                                className = 'error';
                                icon = 'exclamation-circle';
                                tooltip = translate('manageView.addOnsList.fail');
                                message = translate('addonView.extensions.statusErrorFormat', {
                                    message: report.message || '',
                                    environment: report.environment,
                                    clientId: report.clientIdentity,
                                    process: report.process,
                                });
                                break;

                            case 'Disabled':
                                className = 'disabled';
                                icon = 'stop';
                                tooltip = translate('manageView.addOnsList.disabled');
                                message = translate('addonView.extensions.statusFormat', {
                                    message: report.message || '',
                                    environment: report.environment,
                                    clientId: report.clientIdentity,
                                    process: report.process,
                                });
                                break;

                            default:
                                break;
                        }

                        return {
                            icon: icon,
                            className: className,
                            tooltip: tooltip,
                            text: message,
                            clientId: report.clientIdentity
                        };
                    });

                    return messages.map(msg => (
                        <div className={`status-description status-description--${msg.className}`} key={msg.clientId} >
                            <span className={`status-description__icon status-description__icon--${msg.className}`}>
                                <Icon type={msg.icon} theme='outlined' title={msg.tooltip} />
                            </span>
                            <span className='status-description__text'>{msg.text}</span>
                        </div>
                    ));
                }}
            />
        </>);
    }
}

ExtensionsGrid.propTypes = {

    addonId: PropTypes.number.isRequired,
    translate: PropTypes.func.isRequired,
    extensionManager: PropTypes.object.isRequired,
    extensionManagerActions: PropTypes.objectOf(PropTypes.func).isRequired,
};

const mapStateToProps = (state) => ({
    roles: accountSelectors.getAccountRoles(state.account),
    translate: getTranslate(state.locale),
    extensionManager: state.extensionManager,
});

const mapDispatchToProps = (dispatch) => ({
    popupManagerActions: bindActionCreators(popupManagerActions, dispatch),
    extensionManagerActions: bindActionCreators(extensionManagerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionsGrid)
