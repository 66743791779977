export default function(actionType, assignId) {

    return function (payload) {

        if (assignId === true)
        {
            payload.id = +new Date();
        }

        return {
            type: actionType, payload,
        };
    }
}
