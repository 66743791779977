import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import configureReducer from "./configureReducer";
import configureSaga from "./configureSaga";
import configureLocalization from "./configureLocalization";

export default (initialState) => {

    const sagaMiddleware = createSagaMiddleware();
    const middleware = [
        sagaMiddleware,
    ];

    const rootReducer = configureReducer();

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const enhancers = composeEnhancers(applyMiddleware(...middleware));
    const store = createStore(rootReducer, initialState, enhancers);

    configureSaga(sagaMiddleware);
    configureLocalization(store);

    return store;
}
