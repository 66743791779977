import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getTranslate } from "react-localize-redux";
import ContentProvider from "./ContentProvider";
import "./style.scss";

class AddonView extends PureComponent {

    constructor(props) {

        super(props);
        this.addonId = parseInt(props.match.params.id, 10);
    }

    componentWillMount() {

        if (window.pageYOffset > 200) {
            window.scrollTo(0, 200);
        }
    }

    render() {

        return (
            <div className="addon-view transition-item">
                <ContentProvider addonId={this.addonId} goBackHandler={this.onBackButtonClick} />
            </div>
        );
    }
}

AddonView.propTypes = {

    match: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({

    translate: getTranslate(state.locale),
    extensionManager: state.extensionManager,
});

export default connect(mapStateToProps)(AddonView)
