import React, { PureComponent } from "react";
import { ActivityIndicator } from "../index";
import "./index.scss";

class ActivityOverlay extends PureComponent {

    render() {

        return (
            <div className="activity-overlay">
                <ActivityIndicator>
                    <span className="activity-overlay__content">{this.props.children}</span>
                </ActivityIndicator>
            </div>
        );
    }
}

export default ActivityOverlay
