// Quite tricky implementation supported by all the major browsers (Chrome, IE, Edge, FF, Safari)
export default function download(links, onFailed, onDone, onUnauth) {
    const failedLinks = [];
    let isAuthorised = true;
    const download = link => {
        const a = document.createElement("a");
        a.href = link.url;
        a.target = "_parent";

        if ("download" in a)
        {
            a.download = link.fileName;
        }

        (document.body || document.documentElement).appendChild(a);
        a.click();
        a.parentNode.removeChild(a);
    }

    const downloadNext = (i) => {
        if (i >= links.length)
        {
            if (failedLinks.length > 0 && !!onFailed)
            {
                onFailed(failedLinks);
            }
            if (isAuthorised && !!onDone)
            {
                onDone();
            }
            return;
        }

        const link = links[i];
        fetch(link.url, { method: link.method || "head" })
            .then(response => {
                switch(response.status) {
                    case 200:
                        download(link);
                        break;
                    case 401:
                    case 403:
                        onUnauth();
                        isAuthorised = false;
                        break;
                    default:
                        failedLinks.push(link);
                }
            })
            .catch(e => failedLinks.push(link));

        setTimeout(() => isAuthorised && downloadNext(i + 1), 500);
    }

    downloadNext(0);
}
