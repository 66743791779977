import { prefixUrl } from "../../../configuration/config";
import { takeEvery, put, call, apply, select } from "redux-saga/effects";
import { request } from '../../../openid/request';
import { selectors as notificationManagerSelectors } from '../../NotificationManager';
import {
    actions as manageViewActions,
    types as manageViewTypes,
} from "../reducer";


const process = function* (action) {

    const { successMessage, failMessage } = action.payload;

    try
    {
        const fetchParams = [`${prefixUrl}/addon/api/v1/addons`];

        const response = yield call(request, ...fetchParams);
        const data = yield apply(response, response.json);

        switch (response.status) {
            case 200:
                yield put(manageViewActions.getListAddons_done({
                    data,
                    successMessage,
                    failMessage,
                }));
                break;
            case 401:
            case 403:
                yield put(manageViewActions.getListAddons_failed({
                    data: [response.statusText],
                    failMessage: yield select(state => notificationManagerSelectors.getAuthMessage(state.notificationManager)),
                }));
                break;
            default:
                throw data;
        }
    }
    catch (data)
    {
        yield put(manageViewActions.getListAddons_failed({
            data: [data],
            successMessage,
            failMessage,
        }));
    }
}

export default function* () {

    yield takeEvery(manageViewTypes.GET_LIST_ADDONS, process);
}
