import { saga as popupManagerSaga } from "../modules/PopupManager";
import { saga as notificationManagerSaga } from "../modules/NotificationManager";
import { saga as extensionManagerSaga } from "../modules/ExtensionManager";

export default (sagaMiddleware) => {

    const runSaga = sagaMiddleware.run.bind(sagaMiddleware);

    runSaga(popupManagerSaga);
    runSaga(notificationManagerSaga);
    runSaga(extensionManagerSaga);
}
