import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Grid from "./Grid";
import { Checkbox } from "../";
import "./selectable.css";

class SelectableGrid extends Grid {

    constructor() {

        super();
        this.state = {
            selection: [],
            lastClicked: undefined,
        };

        this.getNewSelection = this.getNewSelection.bind(this);
        this.onRowCheckboxClick = this.onRowCheckboxClick.bind(this);
        this.onSelectAllClick = this.onSelectAllClick.bind(this);
    }

    componentWillMount() {

        // Do we need to reset selection here?
        this.setState({
            selection: [],
            lastClicked: undefined,
        });
    }

    componentWillReceiveProps(nextProps) {

        this.setState((state) => {

            const { data } = nextProps;

            const newState = {
                selection: state.selection.filter(itemId => data.byId.includes(itemId)),
            };

            if (state.selection.length !== newState.selection.length)
            {
                const { onSelectionChanged } = this.props;
                if (onSelectionChanged !== undefined)
                {
                    onSelectionChanged(newState.selection);
                }
            }

            return newState;
        });
    }

    getNewSelection(currentSelection, clickedRowId, isRange) {

        const { data } = this.props;
        const { lastClicked } = this.state;
        const addItems = !currentSelection.includes(clickedRowId);
        const startItem = lastClicked || data.byId[0];

        if (isRange && startItem !== clickedRowId)
        {
            const startIndex = data.byId.indexOf(startItem);
            const endIndex = data.byId.indexOf(clickedRowId);

            const sliceParams = startIndex < endIndex
                ? [startIndex, endIndex + 1]
                : [endIndex, startIndex + 1];

            const itemsRange = data.byId.slice(...sliceParams);

            if (addItems)
            {
                const newSelection = [...currentSelection];
                itemsRange.forEach(item => {

                    if (!newSelection.includes(item))
                    {
                        newSelection.push(item);
                    }
                });

                return newSelection;
            }
            else
            {
                return currentSelection.filter(item => !itemsRange.includes(item));
            }
        }
        else
        {
            return addItems
                    ? [...currentSelection, clickedRowId]
                    : currentSelection.filter(item => item !== clickedRowId);
        }
    }

    onRowCheckboxClick(rowId, shiftKey) {

        this.setState(({ selection }) => {

            const newSelection = this.getNewSelection(selection, rowId, shiftKey);

            const { onSelectionChanged } = this.props;
            if (onSelectionChanged !== undefined)
            {
                onSelectionChanged(newSelection);
            }

            return {
                selection: newSelection,
                lastClicked: rowId,
            };
        });
    }

    onSelectAllClick() {

        this.setState(({ selection }) => {

            const { data } = this.props;
            const newSelection = selection.length !== data.byId.length ? [...data.byId] : [];

            const { onSelectionChanged } = this.props;
            if (onSelectionChanged !== undefined)
            {
                onSelectionChanged(newSelection);
            }

            return { selection: newSelection }
        });
    }

    getRowCells(row) {

        const rowCells = [];

        const { data } = this.props;
        const { selection } = this.state;
        const { multiselect, selectAllCheckbox } = this.props;

        if (multiselect === true)
        {
            const checkboxComponent = (row === undefined)
                    ? (selectAllCheckbox === true)
                        ? (<Checkbox onClick={this.onSelectAllClick} defaultChecked={selection.length === data.byId.length} />)
                        : null
                    : (<Checkbox onClick={e => { this.onRowCheckboxClick(row.id, e.shiftKey) }} defaultChecked={selection.includes(row.id)} />);

            rowCells.push(<div key="-1" className="grid-cell grid-checkbox" onClick={e => { e.stopPropagation() }}>
                    {checkboxComponent}
                </div>);
        }

        return rowCells.concat(super.getRowCells(row))
    }

    getRowClassName(row) {

        const { selection } = this.state;

        const className = super.getRowClassName(row);
        return classNames(...className.split(" "), { "selected": selection.includes(row.id) });
    }
}

SelectableGrid.PropTypes = {

    ...Grid.PropTypes,
    multiselect: PropTypes.bool.isRequired,
    selectAllCheckbox: PropTypes.bool.isRequired,
    onSelectionChanged: PropTypes.func,
};

SelectableGrid.defaultProps = {

    ...Grid.defaultProps,
    multiselect: false,
    selectAllCheckbox: false,
};

export default SelectableGrid
