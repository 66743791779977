import { RULES } from "../../modules/PrivateRoute";

const isSecurityEnabled = () => {
    return window.openIdConfiguration && window.openIdConfiguration.isEnabled === true;
}

const isAble = (roles, action) => {
    const accessArray = roles.filter(role => {
        const permissions = RULES[role];

        if (!!permissions && permissions.includes(action)) {
            return true;
        }

        return false;
    });

    return !!accessArray.length || !isSecurityEnabled();
};

const isUnable = (roles, action) => !isAble(roles, action);

const Can = props => {
    const { roles, action, children } = props;

    if (isAble(roles, action)) {
        return children
    }

    if (props.forbidden) {
        props.forbidden();
    } else {
        return null;
    }
}

export default Can;

export {
    isAble,
    isUnable,
    isSecurityEnabled
}
