import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "font-awesome/css/font-awesome.css";
import "./index.scss";

class Alert extends PureComponent {

    constructor() {

        super();
        this.onClick = this.onClick.bind(this);
        this.onCloseButtonClick = this.onCloseButtonClick.bind(this);
        this.getIconClassName = this.getIconClassName.bind(this);
    }

    onClick() {

        const { id, onClick } = this.props;
        if (onClick !== undefined) {
            onClick(id);
        }
    }

    onCloseButtonClick() {

        const {
            id,
            onClose,
        } = this.props;

        if (onClose !== undefined) {
            onClose({ id, force: true });
        }
    }

    getIconClassName() {

        const { type } = this.props;
        switch (type) {
            case "info": return "fa-info-circle";
            case "success": return "fa-check-circle";
            case "warning": return "fa-exclamation-circle";
            case "error": return "fa-times-circle";
            default: return "";
        }
    }

    render() {

        const { type, title, messages, onClose } = this.props;

        return (
            <div onClick={this.onClick} className={`alert ${type}`}>
                {
                    onClose === undefined
                        ? null
                        : <i className="archive fa fa-times" onClick={this.onCloseButtonClick} aria-hidden="true" />
                }
                <i className={`fa ${this.getIconClassName()}`} aria-hidden="true" />

                <div className="alert-content">
                    <span className="alert-title">{title}</span>
                    {messages.map((message, key) => (<div key={key} className="alert-message">{message}</div>))}
                </div>
            </div>
        );
    }
}

Alert.propTypes = {

    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    messages: PropTypes.arrayOf(PropTypes.string),
    onClick: PropTypes.func,
    onClose: PropTypes.func,
};

Alert.defaultProps = {

    messages: [],
}

export default Alert
