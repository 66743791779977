import React from "react";
import classNames from "classnames";
import SelectableGrid from "./SelectableGrid";
import "./navigatable.css";

class NavigatableGrid extends SelectableGrid {

    getRowCells(row) {

        const rowCells = super.getRowCells(row);

        rowCells.push(<div key="-2" className="grid-cell grid-navigation">
            {
                row === undefined
                ? null
                : <span className="grid-label">
                    <i className="fa fa-angle-right" aria-hidden="true" />
                </span>
            }
            </div>);

        return rowCells;
    }

    getGridClassName() {

        const className = super.getGridClassName();
        return classNames(...className.split(" "), "navigatable");
    }
}

export default NavigatableGrid
