import React from 'react';
import { createOidcUserManager } from '../../openid/createOidcUserManager';

export default class SilentRefresh extends React.PureComponent {
    constructor(props) {
        super(props);
        this.silentRefresh = this.silentRefresh.bind(this);
    }

    async silentRefresh() {
        const oidcManager = createOidcUserManager();
        await oidcManager.signinSilentCallback();
    }
    componentDidMount() {
        this.silentRefresh();
    }
    render() {
        return (
            <div className="silentRefresh"></div>
        );
    }
}
