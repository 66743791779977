import { createAction } from "../../utils";

export const types = {

    REGISTER_NOTIFICATION: "REGISTER_NOTIFICATION",
    DISPLAY_NOTIFICATION: "DISPLAY_NOTIFICATION",
    STICK_NOTIFICATION: "STICK_NOTIFICATION",
    ARCHIVE_NOTIFICATION: "ARCHIVE_NOTIFICATION",
    SET_UNAUTHORIZED_DEFAULT: "SET_UNAUTHORIZED_DEFAULT",
};

export const actions = {

    registerNotification: createAction(types.REGISTER_NOTIFICATION, true),
    displayNotification: createAction(types.DISPLAY_NOTIFICATION),
    stickNotification: createAction(types.STICK_NOTIFICATION),
    archiveNotification: createAction(types.ARCHIVE_NOTIFICATION),
    setAuthMessage: createAction(types.SET_UNAUTHORIZED_DEFAULT),
};

const defaultState = {
    unauthorizedMessage: "",
    pending: [],
    active: [],
};

export default (state = defaultState, action) => {

    switch (action.type)
    {
        case types.REGISTER_NOTIFICATION:

            return Object.assign({}, state, {
                pending: [...state.pending, action.payload],
            });

        case types.DISPLAY_NOTIFICATION:

            return Object.assign({}, state, {
                pending: state.pending.filter(notification => notification.id !== action.payload.id),
                active: [...state.active, action.payload],
            });

        case types.STICK_NOTIFICATION:

            const notificationToStick = state.active.filter(notification => notification.id === action.payload);
            if (notificationToStick.length === 1)
            {
                return  Object.assign({}, state, {
                    active: [
                        ...state.active.filter(notification => notification.id !== action.payload),
                        {
                            ...notificationToStick[0],
                            sticky: true,
                        },
                    ]
                });
            }

            return state;

        case types.ARCHIVE_NOTIFICATION:

            const notificationToArchive = state.active.filter(notification => notification.id === action.payload.id);
            if (notificationToArchive.length === 1 && (action.payload.force || notificationToArchive[0].sticky !== true))
            {
                return Object.assign({}, state, {
                    active: state.active.filter(notification => notification.id !== action.payload.id),
                });
            }

            return state;

        case types.SET_UNAUTHORIZED_DEFAULT:
            return {
                ...state,
                unauthorizedMessage: action.payload
            };

        default:

            return state;
    }
}

export const selectors = {
    getAuthMessage: (state) => state.unauthorizedMessage,
    getActiveNotifications: (state) => state.active,
    getPendingNotifications: (state) => state.pending,
}
