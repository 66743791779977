import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getTranslate } from "react-localize-redux";
import { TransitionGroup } from "react-transition-group";
import AnimatedNotification from "./components/AnimatedNotification";
import { actions as notificationManagerActions } from "./reducer";
import { selectors as notificationManagerSelectors } from "./reducer";
import "./style.css";

class NotificationManager extends PureComponent {
    constructor(props) {
        super(props);
        props.notificationManagerActions.setAuthMessage(props.translate("notificationManager.unauthorized"));
    }

    render() {

        const {
            notifications,
            notificationManagerActions,
        } = this.props;

        return (
            <TransitionGroup className="notification-manager">
                {notifications.map((notification, i) => {

                    return (
                        <AnimatedNotification
                            key={notification.id}
                            id={notification.id}
                            type={notification.type}
                            title={notification.title}
                            messages={notification.messages}
                            onClick={notificationManagerActions.stickNotification}
                            onClose={notificationManagerActions.archiveNotification}
                        />);
                })}
            </TransitionGroup>
        );
    }
}

NotificationManager.propTypes = {

    notifications: PropTypes.arrayOf(PropTypes.shape(AnimatedNotification.propTypes).isRequired),
    notificationManagerActions: PropTypes.objectOf(PropTypes.func).isRequired,
};

const mapStateToProps = (state) => ({
    translate: getTranslate(state.locale),
    notifications: notificationManagerSelectors.getActiveNotifications(state.notificationManager),
});

const mapDispatchToProps = (dispatch) => ({

    notificationManagerActions: bindActionCreators(notificationManagerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationManager)
