import React, { PureComponent } from "react";

class BrowseView extends PureComponent {

    render() {

        return (
            <div>Browse view</div>
        );
    }
}

export default BrowseView
