import { prefixUrl } from "../../../configuration/config";
import { takeEvery, put, call, select } from "redux-saga/effects";
import { request } from '../../../openid/request';
import { selectors as notificationManagerSelectors } from '../../NotificationManager';
import {
    actions as manageViewActions,
    types as manageViewTypes,
} from "../reducer";

function* saga(action) {
    let { addonId, successMessage, failMessage } = action.payload;

    const fetchParams = [
        `${prefixUrl}/addon/api/v1/addons/${addonId}/status`,
        { method: "DELETE" },
    ];

    try {
        const response = yield call(request, ...fetchParams);

        switch (response.status) {
            case 200:
                yield put(manageViewActions.getAddon({ id: addonId }));
                yield put(manageViewActions.registerNotificationSuccess({ successMessage }));
                break;
            case 401:
                yield put(manageViewActions.registerNotificationFailed({
                    data: [response.status],
                    failMessage: yield select(state => notificationManagerSelectors.getAuthMessage(state.notificationManager)),
                }));
                break;
            default:
                throw response;
        }
    }
    catch (error) {
        yield put(manageViewActions.registerNotificationFailed({
            data: [error],
            failMessage
        }));
    }
}

export default function* () {

    yield takeEvery(manageViewTypes.DELETE_ADDON_STATUS, saga);
}
