import React, { PureComponent } from "react";
import "./index.css";

class ButtonsGroup extends PureComponent {

    render() {

        return (<div className="buttons-group">
                {this.props.children}
            </div>);
    }
}

export default ButtonsGroup
