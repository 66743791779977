import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./index.scss";

class Badge extends PureComponent {

    render() {

        const { size } = this.props;

        const badgeClassName = classNames({
            "badge": true,
            "big": size === "big",
        });

        return (<span className={`${this.props.className} ${badgeClassName}`}>{this.props.children}</span>);
    }
}

Badge.propTypes = {

    size: PropTypes.oneOf(["normal", "big"]).isRequired,
};

Badge.defaultProps = {

    size: "normal",
};

export default Badge
