export const groupCommands = (...params) => {

    return params;
};

export const createCommand = (icon, label, execute, isAvailable, isDisabled) => ({

    icon,
    label,
    execute,
    isAvailable,
    isDisabled,
});
