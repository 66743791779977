import React, { PureComponent } from "react";
import { Route, Switch } from "react-router-dom";
import ManageView from "./components/ManageView";
import AddonView from "./components/AddonView";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { addonUiUrl } from '../../configuration/config';
import "./style.scss";

class ExtensionManager extends PureComponent {

    render() {

        return (
            <TransitionGroup component={null}>
                <CSSTransition key={this.props.location.pathname} classNames="transition-item" appear timeout={{
                    enter: 400,
                    exit: 300
                }}>
                    <Switch location={this.props.location}>
                        <Route exact path={addonUiUrl} component={ManageView} />
                        <Route path={`${addonUiUrl}/:id`} component={AddonView} />
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
        );
    }
}

export default ExtensionManager;
