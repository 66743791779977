import { delay } from "redux-saga";
import { takeEvery, select, put, call, fork } from "redux-saga/effects";
import {
    actions as notificationManagerActions,
    types as notificationManagerTypes,
    selectors as notificationManagerSelectors,
} from "./reducer";

const maxActiveNotifications = 3;
const defaultNotificationDisplayTime = 5000;

const processDisplayNotification = function*(notification) {

    const activeNotifications = yield select(state => notificationManagerSelectors.getActiveNotifications(state.notificationManager));
    if (activeNotifications.length >= maxActiveNotifications)
    {
        throw new Error("Cannot display more than " + maxActiveNotifications + " at the same time");
    }

    yield put(notificationManagerActions.displayNotification(notification));

    if (notification.sticky !== true)
    {
        yield call(delay, notification.displayTime || defaultNotificationDisplayTime);
        yield put(notificationManagerActions.archiveNotification({ id: notification.id }));
    }
}

const processNotificationActivation = function*() {

    const pendingNotifications = yield select(state => notificationManagerSelectors.getPendingNotifications(state.notificationManager));
    if (pendingNotifications.length === 0)
    {
        return;
    }

    const activeNotifications = yield select(state => notificationManagerSelectors.getActiveNotifications(state.notificationManager));
    if (activeNotifications.length >= maxActiveNotifications)
    {
        return;
    }

    yield fork(processDisplayNotification, pendingNotifications[0]);
}

export default function*() {

    yield takeEvery(notificationManagerTypes.REGISTER_NOTIFICATION, processNotificationActivation);
    yield takeEvery(notificationManagerTypes.ARCHIVE_NOTIFICATION, processNotificationActivation);
}
