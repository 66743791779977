import React, { Component } from 'react';
import './styles.scss';
import { Button } from 'antd';
import { connect } from "react-redux";
import { getTranslate } from "react-localize-redux";
import { ActivityIndicator } from '../../components';

class AboutView extends Component {
    constructor() {
        super();
        this.state = {
            length: {
                group1: 4,
                group2: 7,
            },
            loaded: false,
        }

        this.getData = this.getData.bind(this);
        this.getGroupData = this.getGroupData.bind(this);
    }

    componentDidMount() {
        if (this.props.translate && !this.state.loaded) {
            this.getData();
        }
    }

    render() {
        const { title, desc, group1, group2, sidebar, loaded } = this.state;

        if (loaded) {
            return (
                <section className="develop">
                    <div className="develop__content">
                        <header className="develop__header">
                            <h2 className="develop__title">{title}</h2>
                            <h4 className="develop__about">{desc}</h4>
                        </header>
                        <div className="develop__columns">
                            <h4 className="develop__group-title">{group1.title}</h4>
                            <div className="develop__column">
                                <GroupRenderer {...group1} />
                            </div>
                            <h4 className="develop__group-title">{group2.title}</h4>
                            <div className="develop__column">
                                <GroupRenderer {...group2} />
                            </div>
                        </div>
                    </div>
                    <div className="develop__sidebar">
                        <div className="develop__appstore-title">
                            {sidebar.title}
                        </div>
                        <div className="develop__appstore-text">
                            {sidebar.desc}
                        </div>
                        <a href={sidebar.button.url} target='_blank' rel="noopener noreferrer" >
                            <Button className="develop__appstore-button">{sidebar.button.label}</Button>
                        </a>
                    </div>
                </section>
            );
        }

        return <ActivityIndicator />
    }

    getData() {
        const { translate } = this.props;

        const group1Data = this.getGroupData('group1');
        const group2Data = this.getGroupData('group2');

        this.setState({
            title: translate('develop.title'),
            desc: translate('develop.desc'),
            group1: {
                title: translate('develop.group1.title'),
                data: group1Data,
            },
            group2: {
                title: translate('develop.group2.title'),
                data: group2Data,
            },
            sidebar: {
                title: translate('develop.sidebar.title'),
                desc: translate('develop.sidebar.desc'),
                button: {
                    label: translate('develop.sidebar.button.label'),
                    url: translate('develop.sidebar.button.url'),
                }
            },
            loaded: true,
        });
    }

    getGroupData(groupName) {
        const { translate } = this.props;

        let arr = [];
        for (let i = 0; i < this.state.length[groupName]; i++) {
            arr.push({
                title: translate(`develop.${groupName}.data.${i}.title`),
                desc: translate(`develop.${groupName}.data.${i}.desc`),
                link: translate(`develop.${groupName}.data.${i}.link`),
            });
        }

        return arr;
    }
}

const GroupRenderer = ({data}) => {
    return data.map(item => (
        <a className="link-card develop__column-item" href={item.link} target='_blank' rel="noopener noreferrer" >
            <div className="link-card__title">{item.title}</div>
            <article className="link-card__text">{item.desc}</article>
        </a>
    ))
};

const mapStateToProps = (state) => ({
    translate: getTranslate(state.locale),
});

export default connect(mapStateToProps)(AboutView);
