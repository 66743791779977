import { createAction } from "../../utils";
import { ROLES } from './rbac-rules';

export const types = {
    SET_ACCOUNT: "SET_ACCOUNT",
    SET_SECURITY: "SET_SECURITY",
};

export const actions = {
    setAccount: createAction(types.SET_ACCOUNT),
    setSecurity: createAction(types.SET_SECURITY),
};

const defaultState = {
    roles: [ROLES.VISITOR],
    name: 'guest',
    isSecure: false,
};

export default (state = defaultState, action) => {

    switch (action.type) {
        case types.SET_ACCOUNT:
            const { roles, name } = action.payload;
            return {
                ...state,
                roles,
                name
            }
        case types.SET_SECURITY:
            const { payload } = action;
            return {
                ...state,
                isSecure: payload
            }
        default:
            return state;
    }
}

export const selectors = {
    getUserName: state => state.name,
    getAccountRoles: state => state.roles,
    getIsSecure: state => state.isSecure,
}
