import React from 'react';
import "./style.css";
import { ActivityOverlay } from '../../components';
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { KEY_REDIRECT } from '../../openid/constants';

class LoginCallback extends React.PureComponent {

    constructor(props) {
        super(props);
        this.signinCallback = this.signinCallback.bind(this);
    }

    async signinCallback() {
        const { oidcManager } = this.props;
        await oidcManager.signinRedirectCallback();
        const redirect = localStorage.getItem(KEY_REDIRECT);
        if (redirect) {
            window.location.href = redirect;
        }
    }

    componentDidMount() {
        this.signinCallback();
    }

    render() {
        const { translate } = this.props;
        return (
            <div className="loginCallback">
                <ActivityOverlay>{translate("header.redirectText")}</ActivityOverlay>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({

    translate: getTranslate(state.locale),
});

export default connect(mapStateToProps)(LoginCallback);
