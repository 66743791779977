import { createAction } from "../../utils";

export const types = {

    GET_ADDON: "GET_ADDON",
    GET_ADDON_DONE: "GET_ADDON_DONE",
    GET_ADDON_NOTFOUND: "GET_ADDON_NOTFOUND",
    GET_ADDON_FAILED: "GET_ADDON_FAILED",
    GET_LIST_ADDONS: "GET_LIST_ADDONS",
    GET_LIST_ADDONS_DONE: "GET_LIST_ADDONS_DONE",
    GET_LIST_ADDONS_FAILED: "GET_LIST_ADDONS_FAILED",
    POST_PACKAGE: "POST_PACKAGE",
    POST_PACKAGE_DONE: "POST_PACKAGE_DONE",
    POST_PACKAGE_FAILED: "POST_PACKAGE_FAILED",
    DELETE_PACKAGES: "DELETE_PACKAGES",
    DELETE_PACKAGES_DONE: "DELETE_PACKAGES_DONE",
    DELETE_PACKAGES_FINISHED: "DELETE_PACKAGES_FINISHED",
    DELETE_PACKAGES_FAILED: "DELETE_PACKAGES_FAILED",
    POST_CONFIGURATION: "POST_CONFIGURATION",
    POST_CONFIGURATION_FAILED: "POST_CONFIGURATION_FAILED",
    DELETE_CONFIGURATION: "DELETE_CONFIGURATION",
    REGISTER_NOTIFICATION_SUCCESS: "REGISTER_NOTIFICATION_SUCCESS",
    REGISTER_NOTIFICATION_FAILED: "REGISTER_NOTIFICATION_FAILED",
    PUT_PACKAGE: "PUT_PACKAGE",
    DELETE_EXTENSION_STATUS: "DELETE_EXTENSION_STATUS",
    DELETE_ADDON_STATUS: "DELETE_ADDON_STATUS",
};

export const actions = {

    getAddon: createAction(types.GET_ADDON),
    getAddon_done: createAction(types.GET_ADDON_DONE),
    getAddon_notFound: createAction(types.GET_ADDON_NOTFOUND),
    getAddon_failed: createAction(types.GET_ADDON_FAILED),
    getListAddons: createAction(types.GET_LIST_ADDONS),
    getListAddons_done: createAction(types.GET_LIST_ADDONS_DONE),
    getListAddons_failed: createAction(types.GET_LIST_ADDONS_FAILED),
    postPackage: createAction(types.POST_PACKAGE),
    postPackage_done: createAction(types.POST_PACKAGE_DONE),
    postPackage_failed: createAction(types.POST_PACKAGE_FAILED),
    deletePackages: createAction(types.DELETE_PACKAGES),
    deletePackages_done: createAction(types.DELETE_PACKAGES_DONE),
    deletePackages_finished: createAction(types.DELETE_PACKAGES_FINISHED),
    deletePackages_failed: createAction(types.DELETE_PACKAGES_FAILED),
    postConfiguration: createAction(types.POST_CONFIGURATION),
    postConfiguration_failed: createAction(types.POST_CONFIGURATION_FAILED),
    deleteConfiguration: createAction(types.DELETE_CONFIGURATION),
    registerNotificationSuccess: createAction(types.REGISTER_NOTIFICATION_SUCCESS),
    registerNotificationFailed: createAction(types.REGISTER_NOTIFICATION_FAILED),
    putPackage: createAction(types.PUT_PACKAGE),
    deleteExtensionStatus: createAction(types.DELETE_EXTENSION_STATUS),
    deleteAddonStatus: createAction(types.DELETE_ADDON_STATUS),
};

const defaultState = {

    isListLoading: false,
    isListLoadFailed: false,
    isAddonLoading: false,
    isAddonLoadFailed: false,
    isUploading: false,
    isDeleting: false,

    addons: {
        byId: [],
        byHash: {},
    },
};

export default (state = defaultState, action) => {

    switch (action.type) {
        case types.GET_ADDON:

            return Object.assign({}, state, { isAddonLoading: true });

        case types.GET_ADDON_DONE:

            let newState = Object.assign({}, state, { isAddonLoading: false, isAddonLoadFailed: false });

            const data = action.payload.data;
            const exist = state.addons.byId.indexOf(data.id) >= 0;
            if (!exist) {
                return Object.assign({}, newState, {
                    addons: {
                        byId: [...state.addons.byId, data.id],
                        byHash: {
                            ...state.addons.byHash,
                            [data.id]: { ...data },
                        },
                    },
                });
            }

            newState.addons.byHash[data.id] = { ...data };
            return newState;

        case types.GET_ADDON_NOTFOUND:

            return Object.assign({}, state, { isAddonLoading: false, isAddonLoadFailed: false });

        case types.GET_ADDON_FAILED:

            return Object.assign({}, state, { isAddonLoading: false, isAddonLoadFailed: true });

        case types.GET_LIST_ADDONS:

            return Object.assign({}, state, { isListLoading: true });

        case types.GET_LIST_ADDONS_DONE:

            const newAddons = {
                byId: [],
                byHash: {},
            };

            action.payload.data.forEach(addon => {
                newAddons.byId.push(addon.id);
                newAddons.byHash[addon.id] = { ...addon };
            });

            return Object.assign({}, state, { addons: newAddons, isListLoading: false, isListLoadFailed: false });

        case types.GET_LIST_ADDONS_FAILED:

            return Object.assign({}, state, { isListLoading: false, isListLoadFailed: true });

        case types.POST_PACKAGE:

            return Object.assign({}, state, { isUploading: true });

        case types.POST_PACKAGE_DONE:

            return Object.assign({}, state, { isUploading: false });

        case types.POST_PACKAGE_FAILED:

            return Object.assign({}, state, { isUploading: false });

        case types.DELETE_PACKAGES:

            return Object.assign({}, state, { isDeleting: true });

        case types.DELETE_PACKAGES_DONE:

            const packageId = action.payload.packageId;

            const pruneIds = state.addons.byId.filter(item => item !== packageId);
            const pruneHashes = { ...state.addons.byHash };
            delete pruneHashes[packageId];

            return Object.assign({}, state, {
                addons: {
                    byId: pruneIds,
                    byHash: pruneHashes,
                }
            });

        case types.DELETE_PACKAGES_FINISHED:

            return Object.assign({}, state, { isDeleting: false });

        case types.DELETE_PACKAGES_FAILED:

            return Object.assign({}, state, { isDeleting: false });

        default:

            return state;
    }
}

export const selectors = {

    isListLoading: (state) => state.isListLoading,
    isListLoadFailed: (state) => state.isListLoadFailed,
    isAddonLoading: (state) => state.isAddonLoading,
    isAddonLoadFailed: (state) => state.isAddonLoadFailed,
    isUploading: (state) => state.isUploading,
    isDeleting: (state) => state.isDeleting,

    getAddons: (state) => state.addons,
    getAddonsIds: (state) => state.addons.byId,
    getAddonsCount: (state) => state.addons.byId.length,

    getAddonById: (state, id) => state.addons.byHash[id] || null,
    getAddonExtensionsById: (state, id) => {

        const addon = state.addons.byHash[id] || null;
        if (addon !== null) {
            return addon.extensions;
        }
        return null;
    },
    getAddonExtensionsCountById: (state, id) => {

        const addon = state.addons.byHash[id] || null;
        if (addon !== null) {
            return addon.extensions.length;
        }
        return null;
    }
}
