import { KEY_ACCESS_TOKEN } from './constants';

function createBearerTokenString(token) {
    return `Bearer ${token}`;
}

function getAccessToken() {
    const token = localStorage.getItem(KEY_ACCESS_TOKEN);
    return token;
}

export async function request(url, init={}) {
    const token = getAccessToken();
    if (token !== null) {
        const tokenString = createBearerTokenString(token);
        const initHeader = { headers: { Authorization : tokenString } };
        init = {...init, ...initHeader};
    }
    return await fetch(url, init);
}

export function createUrlWithToken(url) {
    const token = getAccessToken();
    if (token !== null) {
        const tokenString = createBearerTokenString(token);
        return `${url}?authtoken=${tokenString}`;
    }
    return url;
}
