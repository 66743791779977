import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./index.scss";

class Navigation extends PureComponent {

    render() {

        const { style } = this.props;

        return (
            <nav className="navigation" style={style}>
                <div className="navigation__container">
                    {this.props.children}
                </div>
            </nav>
        );
    }
}

Navigation.propTypes = {

    style: PropTypes.object.isRequired,
};

Navigation.defaultProps = {

    style: {},
}

export default Navigation
