import { takeEvery, put } from "redux-saga/effects";
import { actions as notificationManagerActions } from "../../NotificationManager/reducer";
import { types as manageViewTypes } from "../reducer";

const processActionSuccess = function* (action) {

    yield put(notificationManagerActions.registerNotification({
        type: "success",
        title: action.payload.successMessage,
    }));
}

const processActionFailed = function* (action) {

    const { data, failMessage } = action.payload;

    if (data.length > 0) {
        console.error(failMessage, data);
    }

    yield put(notificationManagerActions.registerNotification({
        type: "error",
        title: failMessage,
        messages: data
            .filter(error => error !== undefined && error.Message !== undefined)
            .map(error => error.Message),
    }));
}

export default function* () {

    yield takeEvery(manageViewTypes.GET_LIST_ADDONS_FAILED, processActionFailed);
    yield takeEvery(manageViewTypes.POST_PACKAGE_DONE, processActionSuccess);
    yield takeEvery(manageViewTypes.POST_PACKAGE_FAILED, processActionFailed);
    yield takeEvery(manageViewTypes.DELETE_PACKAGES_FINISHED, processActionSuccess);
    yield takeEvery(manageViewTypes.DELETE_PACKAGES_FAILED, processActionFailed);
    yield takeEvery(manageViewTypes.REGISTER_NOTIFICATION_SUCCESS, processActionSuccess);
    yield takeEvery(manageViewTypes.REGISTER_NOTIFICATION_FAILED, processActionFailed);
}
