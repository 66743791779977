import React, { PureComponent } from "react";
import { CSSTransition } from "react-transition-group";
import { Popup } from "../../../../components";
import Overlay from "../Overlay";
import "./style.css";

class AnimatedPopup extends PureComponent {

    render() {

        return (
            <Overlay>
                <CSSTransition {...this.props} timeout={200} classNames="animated-popup">
                    <Popup {...this.props} />
                </CSSTransition>
            </Overlay>
        );
    }
}

AnimatedPopup.propTypes = Popup.propTypes;

export default AnimatedPopup
