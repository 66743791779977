import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { Button } from "../"
import "./index.scss";

class Popup extends PureComponent {

    constructor() {

        super();

        let _historyLock = undefined;
        this.blockHistory = function () { _historyLock = this.props.history.block(); }
        this.unblockHistory = function () { _historyLock = _historyLock !== undefined ? _historyLock() : _historyLock }

        this.actionHandler = this.actionHandler.bind(this);
        this.close = this.close.bind(this);
    }

    componentWillMount() {

        this.blockHistory();
    }

    actionHandler() {

        this.props.actionHandler();
        this.close();
    }

    close() {

        this.unblockHistory();
        this.props.onClose();
    }

    render() {

        const popupClassNames = classNames({
            "popup": true,
            "danger": this.props.danger,
        });

        return (<div className={popupClassNames}>
            <i className="fa fa-question-circle-o" aria-hidden="true" />
            <h1>{this.props.title}</h1>
            <p>{this.props.message}</p>
            <div className="buttons">
                <Button onClick={this.actionHandler} label={this.props.actionLabel} type="primary" />
                <Button onClick={this.close} label={this.props.cancelLabel} />
            </div>
        </div>);
    }
}

Popup.propTypes = {

    icon: PropTypes.string.isRequired,
    danger: PropTypes.bool,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    actionLabel: PropTypes.string.isRequired,
    actionHandler: PropTypes.func.isRequired,
    cancelLabel: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default withRouter(Popup)
