import React, { PureComponent } from "react";
import "./index.scss";

import bgImage from "./hero-image-bg.svg";

class Header extends PureComponent {

    render() {

        const backgroundStyle = {
            background: `url(${bgImage}), linear-gradient(5deg, #298fc2, #25bd59)`,
        };

        return (
            <div className="header" style={backgroundStyle}>
                {this.props.children}
            </div>
        );
    }
}

export default Header
