import { createAction } from "../../utils";

export const types = {

    REGISTER_POPUP: "REGISTER_POPUP",
    DISPLAY_POPUP: "DISPLAY_POPUP",
    ARCHIVE_POPUP: "ARCHIVE_POPUP",
};

export const actions = {

    registerPopup: createAction(types.REGISTER_POPUP, true),
    displayPopup: createAction(types.DISPLAY_POPUP),
    archivePopup: createAction(types.ARCHIVE_POPUP),
};

const defaultState = {

    pending: [],
    active: null,
};

export default (state = defaultState, action) => {

    switch (action.type)
    {
        case types.REGISTER_POPUP:

            return Object.assign({}, state, {
                pending: [...state.pending, action.payload],
            });

        case types.DISPLAY_POPUP:

            return Object.assign({}, state, {
                pending: state.pending.filter(popup => popup.id !== action.payload.id),
                active: {...action.payload},
            });

        case types.ARCHIVE_POPUP:

            return Object.assign({}, state, {
                active: null,
            });

        default:

            return state;
    }
}

export const selectors = {

    getActivePopup: (state) => state.active,
    getPendingPopups: (state) => state.pending,
}
