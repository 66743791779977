import { prefixUrl } from "../../../configuration/config";
import { takeEvery, put, call, apply, select } from "redux-saga/effects";
import { request } from '../../../openid/request';
import { selectors as notificationManagerSelectors } from '../../NotificationManager';
import {
    actions as manageViewActions,
    types as manageViewTypes,
} from "../reducer";

const process = function* (action) {

    const {
        successMessage,
        failMessage,
        extensionPackages,
        callback,
    } = action.payload;

    const errorData = [];
    let isAuthRequest = true;
    for (let packageId of extensionPackages)
    {
        const fetchParams = [
            `${prefixUrl}/addon/api/v1/addons/${packageId}`,
            { method: "DELETE" },
        ];

        try
        {
            const response = yield call(request, ...fetchParams);

            switch (response.status) {
                case 200:
                case 404:
                    yield put(manageViewActions.deletePackages_done({ packageId }));
                    break;
                case 401:
                case 403:
                    yield put(manageViewActions.getListAddons_failed({
                        data: [response.textResponse],
                        failMessage: yield select(state => notificationManagerSelectors.getAuthMessage(state.notificationManager)),
                    }));
                    isAuthRequest = false;
                    break;
                default:
                    throw yield apply(response, response.json);
            }
        }
        catch (data)
        {
            errorData.push(data);
        }
    }

    if (isAuthRequest) {
        if (errorData.length === 0) {
            yield put(manageViewActions.deletePackages_finished({ successMessage }));
            if (callback !== undefined) {
                yield call(callback);
            }
        }
        else {
            yield put(manageViewActions.deletePackages_failed({ data: errorData, failMessage }));
        }
    }
}

export default function* () {

    yield takeEvery(manageViewTypes.DELETE_PACKAGES, process);
}
