import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, BrowserRouter, Switch, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { getTranslate } from "react-localize-redux";
import { StickyContainer, Sticky } from "react-sticky";
import NotificationManager from "../NotificationManager";
import PopupManager from "../PopupManager";
import ExtensionManager from "../ExtensionManager";
import BrowseView from "../BrowseView";
import NotFoundView from "../NotFoundView";
import { Header, Navigation } from "../../components";
import { selectors as accountSelectors } from '../PrivateRoute';
import { Alert } from 'antd';
import { addonUiUrl } from '../../configuration/config';
import "./style.scss";
import "../../fonts.css";
import AboutView from "../AboutView/AboutView";

class Layout extends Component {
    constructor() {
        super();
        this.getAllert = this.getAllert.bind(this);
    }

    getAllert(props) {
        if (this.props.accountIsSecure) {
            return null;
        }
        return <Alert className="app__alert" message={props.text} banner />
    }

    render() {

        const { translate } = this.props;
        const AllertComponent = this.getAllert;

        return (
            <StickyContainer className="app__wrapper">
                <BrowserRouter>
                    <div className="app">
                        <AllertComponent text={translate("notificationManager.isNotSecureAlert")} />
                        <Header>
                            <h1 className="header__title">{translate("header.title")}</h1>
                            <p className="header__subtitle">{translate("header.description")}</p>
                        </Header>
                        <Sticky topOffset={200}>
                            {params => {

                                const style = { ...params.style };
                                style.zIndex = 1;
                                return (
                                    <Navigation style={style}>
                                        <NavLink exact to={addonUiUrl}>{translate("navigation.manage")}</NavLink>
                                        <NavLink exact to={addonUiUrl + '/develop'}>{translate("navigation.findAndDevelop")}</NavLink>
                                    </Navigation>
                                );
                            }}
                        </Sticky>
                        <section className="content">
                            <div className="content__container">
                                <Switch>
                                    <Route exact path="/">
                                        <Redirect to={addonUiUrl} />
                                    </Route>
                                    <Route exact path={addonUiUrl + '/browse'} component={BrowseView} />
                                    <Route exact path={addonUiUrl + '/develop'} component={AboutView} />
                                    <Route path={addonUiUrl} component={ExtensionManager} />
                                    <Route path="*" component={NotFoundView} />
                                </Switch>
                            </div>
                        </section>
                        <PopupManager />
                        <NotificationManager />
                    </div>
                </BrowserRouter>
            </StickyContainer>
        );
    }
}

Layout.propTypes = {

    translate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    accountIsSecure: accountSelectors.getIsSecure(state.account),
    translate: getTranslate(state.locale),
});

export default connect(mapStateToProps)(Layout)
