import React, { PureComponent } from "react";
import "./style.css";

class Overlay extends PureComponent {

    render() {

        return (<div className="overlay">{this.props.children}</div>)
    }
}

export default Overlay
