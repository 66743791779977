import { prefixUrl } from "../../../configuration/config";
import { takeEvery, put, call, apply, select } from "redux-saga/effects";
import { request } from '../../../openid/request';
import { selectors as notificationManagerSelectors } from '../../NotificationManager';
import {
    actions as manageViewActions,
    types as manageViewTypes
} from "../reducer";

const process = function* (action) {

    const {
        successMessage,
        failMessage,
        extensionPackage
    } = action.payload;

    const formData = new FormData();
    formData.append("File", extensionPackage);

    const fetchParams = [
        `${prefixUrl}/addon/api/v1/addons`,
        {
            method: "POST",
            body: formData
        }
    ];

    try {
        const response = yield call(request, ...fetchParams);

        switch (response.status) {
            case 201:
                yield put(manageViewActions.postPackage_done({
                    successMessage: successMessage.new
                }));

                yield put(manageViewActions.getListAddons({}));
                break;

            case 202:
                const data = yield apply(response, response.json);
                data.isModified
                    ? yield put(manageViewActions.postPackage_done({ successMessage: successMessage.replace }))
                    : yield put(manageViewActions.postPackage_done({ successMessage: successMessage.notModified }));
                yield put(manageViewActions.getListAddons({}));
                break;
            case 400:
                throw yield apply(response, response.json);
            case 401:
            case 403:
                yield put(manageViewActions.getListAddons_failed({
                    data: [response.statusText],
                    failMessage: yield select(state => notificationManagerSelectors.getAuthMessage(state.notificationManager))
                }));
                break;
            default:
                throw response.statusText;
        }
    }
    catch (data) {
        yield put(manageViewActions.postPackage_failed({
            data: [data],
            successMessage: successMessage.new,
            failMessage: failMessage.new
        }));
    }
}

export default function* () {

    yield takeEvery(manageViewTypes.POST_PACKAGE, process);
}
