import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Sticky } from "react-sticky";
import { ActivityOverlay } from "../";
import "./grid.scss";

class Grid extends PureComponent {

    constructor() {

        super();
        this.onRowClick = this.onRowClick.bind(this);
        this.getRow = this.getRow.bind(this);
        this.getRowCells = this.getRowCells.bind(this);
        this.getCellData = this.getCellData.bind(this);
        this.getGridClassName = this.getGridClassName.bind(this);
        this.getRowClassName = this.getRowClassName.bind(this);
    }

    onRowClick(rowId) {

        const { onItemClick } = this.props;
        if (onItemClick !== undefined) {
            onItemClick(rowId);
        }
    }

    getRow(row, index) {

        return (<div key={index} onClick={() => this.onRowClick(row.id)} className={this.getRowClassName(row)}>
            {this.getRowCells(row)}
        </div>);
    }

    getRowCells(row) {

        const rowCells = [];
        const { columns } = this.props;

        columns.forEach((columnDefinition, i) => {

            const cellValue = (row === undefined)
                ? columnDefinition.title
                : this.getCellData(row, columnDefinition);

            const cellClassName = classNames({
                "grid-cell": true,
                "grow0": columnDefinition.columnGrow === 0,
                "grow2": columnDefinition.columnGrow === 2,
                "grow3": columnDefinition.columnGrow === 3,
            });

            rowCells.push(<div key={i} className={cellClassName}>
                <span className="grid-label">{cellValue}</span>
            </div>);
        });

        return rowCells;
    }

    getCellData(data, columnDefinition) {

        const { dataPath, valueDecorator } = columnDefinition;
        const paths = dataPath.split(".");

        let value = data;
        for (let i = 0; i < paths.length; i++) {
            value = value[paths[i]];
            if (value === undefined) {
                break;
            }
        }

        return valueDecorator === undefined ? value : valueDecorator(value, data);
    }

    getGridClassName() {

        const { isLoading } = this.props;

        return classNames({
            "grid": true,
            "loading": isLoading === true,
        });
    }

    getRowClassName(row) {

        return classNames({
            "grid-row": true,
            "grid-row--warning": row.requireConfiguration === 'Yes' && !row.configuration,
        });
    }

    render() {

        const { isLoading, data, sticky, stickyTopOffset, stickyFreezePozition } = this.props;

        const header = sticky ?
            (<Sticky topOffset={stickyTopOffset}>
                {params => {
                    const style = { ...params.style };
                    style.top = stickyFreezePozition;
                    style.zIndex = 1;
                    return (<div className="grid-header grid-row" style={style}>{this.getRowCells()}</div>);
                }}
            </Sticky>) :
            (<div className="grid-header grid-row">{this.getRowCells()}</div>);

        return (<div className={this.getGridClassName()}>
            {header}
            {data.byId.map((id, i) => {
                const row = data.byHash[id];
                return this.getRow(row, i);
            })}
            {isLoading === true ? (<ActivityOverlay />) : null}
        </div>);
    }
}

Grid.propTypes = {

    sticky: PropTypes.bool.isRequired,
    stickyTopOffset: PropTypes.number,
    stickyFreezePozition: PropTypes.number,

    columns: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        columnGrow: PropTypes.number,
        dataPath: PropTypes.string.isRequired,
        valueDecorator: PropTypes.func,
    })).isRequired,
    data: PropTypes.shape({
        byId: PropTypes.arrayOf(PropTypes.number).isRequired,
        byHash: PropTypes.object.isRequired,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    onItemClick: PropTypes.func,
};

Grid.defaultProps = {

    sticky: false,
    isLoading: false,
};

export default Grid
