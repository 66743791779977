import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getTranslate } from "react-localize-redux";
import { Badge, GridWithActions } from "../../../../components";
import { actions as popupManagerActions } from "../../../PopupManager";
import {
    actions as notificationManagerActions,
    selectors as notificationManagerSelectors } from "../../../NotificationManager";
import {
    actions as extensionManagerActions,
    selectors as extensionManagerSelectors } from "../../../ExtensionManager";
import { selectors as accountSelectors, ACTIONS } from '../../../PrivateRoute';
import { createCommand, groupCommands, download, formatDateString } from "../../../../utils";
import "font-awesome/css/font-awesome.css";
import defaultIcon from '../extension.128.svg';
import { Icon } from 'antd';
import { isAble } from "../../../../components/Can";
import { addonUiUrl } from '../../../../configuration/config';
import { createUrlWithToken } from "../../../../openid/request";

class AddonsGrid extends PureComponent {

    constructor(props, context) {

        super(props, context);

        this.state = {
            isDownloading: false,
        };

        this.getGridProps = this.getGridProps.bind(this);
        this.getCommands = this.getCommands.bind(this);
        this.onGridItemClick = this.onGridItemClick.bind(this);
    }

    getGridProps() {

        const { translate, roles } = this.props;

        const gridProps = {

            multiselect: isAble(roles, ACTIONS.addon.download) || isAble(roles, ACTIONS.addon.delete),
            selectAllCheckbox: isAble(roles, ACTIONS.addon.download) || isAble(roles, ACTIONS.addon.delete),
            columns: [
                {
                    title: "",
                    columnGrow: 0,
                    dataPath: "downloadIconUri",
                    valueDecorator: (value) => {
                        if (value) {
                            return <img className="manage-view__addon-icon" src={value} alt="" />;
                        }
                        return <img className="manage-view__addon-icon" src={defaultIcon} alt="" />;
                    }
                },
                {
                    title: translate("manageView.addOnsList.name"),
                    columnGrow: 2,
                    dataPath: "name",
                    valueDecorator: (value, row) => {
                        return (<div className="grid-addon-title grid-cell-truncate-wrapper">
                            <span className="grid-addon-name grid-cell-truncate" title={value}>{value}</span>
                            <Badge>{row.extensions.length}</Badge>
                        </div>);
                    },
                },
                {
                    title: translate("manageView.addOnsList.version"),
                    dataPath: "version",
                },
                {
                    title: translate("manageView.addOnsList.status"),
                    dataPath: "status",
                    valueDecorator: (value) => {
                        let modifier, icon, text, theme;

                        switch (value) {
                            case 'Pending':
                                modifier = 'pending';
                                icon = 'loading';
                                text = translate('manageView.addOnsList.pending');
                                break;
                            case 'Success':
                                modifier = 'success';
                                icon = 'check-circle';
                                text = translate('manageView.addOnsList.success');
                                break;
                            case 'Fail':
                                modifier = 'error';
                                icon = 'exclamation-circle';
                                text = translate('manageView.addOnsList.fail');
                                theme = 'filled';
                                break;
                            case 'WaitingConfiguration':
                                modifier = 'error';
                                icon = 'exclamation-circle';
                                text = translate('manageView.addOnsList.waitingConfiguration');
                                theme = 'filled';
                                break;
                            default:
                                break;

                        }

                        return (
                            <div className="grid-cell-truncate-wrapper">
                                <div className={`status-tag status-tag--${modifier} grid-cell-truncate`}>
                                    <span className="status-tag__icon">
                                        <Icon type={icon} theme={theme} />
                                    </span>
                                    <span className="status-tag__text">{text}</span>
                                </div>
                            </div>

                        )
                    },
                },
                {
                    title: translate("manageView.addOnsList.config"),
                    dataPath: "requireConfiguration",
                    valueDecorator: (value, data) => {
                        let mod, text;
                        if(!!data.configuration)
                        {
                            mod = "grey";
                            text = formatDateString(data.configuration.uploadedAt);
                        }
                        else
                        {
                            switch (value)
                            {
                                case 'Yes':
                                    mod = "red";
                                    text = translate("manageView.addOnsList.configRequired");
                                    break;

                                case 'Optional':
                                    mod = "grey";
                                    text = translate("manageView.addOnsList.configOptional");
                                    break;

                                default:
                                    mod = "grey";
                                    text = translate("manageView.addOnsList.configNA");
                            }
                        }

                        return (
                            <div className={`grid-addon-config grid-addon-config--${mod}`}>
                                <span className="grid-addon-config-text" title={text}>{text}</span>
                            </div>
                        )
                    }
                },
                {
                    title: translate("manageView.addOnsList.author"),
                    dataPath: "author",
                    valueDecorator: (value) => {
                        return (
                            <div className="grid-cell-truncate-wrapper">
                                <div className="grid-cell-truncate grid-addon-author" title={value}>{value}</div>
                            </div>
                        )
                    }
                },
                {
                    title: translate("manageView.addOnsList.uploadedAt"),
                    dataPath: "uploadedAt",
                    valueDecorator: (value) => (<span>{formatDateString(value)}</span>),
                },
            ],
        };

        return gridProps;
    }

    getCommands() {

        const { isDownloading } = this.state;
        const { translate, extensionManager, roles, notificationManager } = this.props;
        const { registerPopup } = this.props.popupManagerActions;
        const { registerNotification } = this.props.notificationManagerActions;
        const { getListAddons, deletePackages } = this.props.extensionManagerActions;

        return [
            groupCommands(
                createCommand(
                    "reload",
                    translate("manageView.actionBar.refresh"),
                    () => getListAddons({ failMessage: translate("manageView.notifications.getListAddonsFailed") }),
                ),
            ),
            groupCommands(
                createCommand(
                    "download",
                    translate("manageView.actionBar.download"),
                    (selection) => {
                        if (isDownloading === false) {
                            this.setState({ isDownloading: true });
                            download(
                                selection.map(addonId => {
                                    const addon = extensionManagerSelectors.getAddonById(extensionManager, addonId);
                                    return {
                                        url: createUrlWithToken(addon.downloadUri),
                                        fileName: `${addon.name}.zip`,
                                    }
                                }),
                                failedLinks => {
                                    let errorMessage = null;
                                    if (selection.length === 1) {
                                        const addon = extensionManagerSelectors.getAddonById(extensionManager, selection[0]);
                                        errorMessage = translate("manageView.notifications.downloadFailed", { packageName: addon.name })
                                    }
                                    else {
                                        errorMessage = translate("manageView.notifications.multiDownloadFailed")
                                    }

                                    registerNotification({
                                        type: "error",
                                        title: errorMessage,
                                        messages: selection.length > 1 ? failedLinks.map(p => p.fileName) : undefined,
                                    })
                                },
                                () => this.setState({ isDownloading: false }),
                                () => registerNotification({
                                    type: "error",
                                    title: notificationManagerSelectors.getAuthMessage(notificationManager),
                                }),
                            );
                        }
                    },
                    (selection) => {
                        return (selection.length > 0) && isAble(roles, ACTIONS.addon.download);
                    },
                ),
            ),
            groupCommands(
                createCommand(
                    "delete",
                    translate("manageView.actionBar.remove"),
                    (selection) => {
                        const firstAddon = extensionManagerSelectors.getAddonById(extensionManager, selection[0]);
                        registerPopup({
                            icon: "fa-question",
                            danger: true,
                            title: selection.length === 1
                                ? translate("manageView.deleteConfirmation.title", { addonName: firstAddon.name })
                                : translate("manageView.deleteConfirmation.multiTitle", { count: selection.length }),
                            message: translate("manageView.deleteConfirmation.message"),
                            actionLabel: translate("manageView.deleteConfirmation.remove"),
                            cancelLabel: translate("manageView.deleteConfirmation.cancel"),
                            actionHandler: () => deletePackages({
                                extensionPackages: selection,
                                successMessage: selection.length === 1
                                    ? translate("manageView.notifications.removeSuccess", { packageName: firstAddon.name })
                                    : translate("manageView.notifications.multiRemoveSuccess", { count: selection.length }),
                                failMessage: selection.length === 1
                                    ? translate("manageView.notifications.removeFailed", { packageName: firstAddon.name })
                                    : translate("manageView.notifications.multiRemoveFailed"),
                            }),
                        })
                    },
                    (selection) => {
                        return (selection.length > 0) && isAble(roles, ACTIONS.addon.delete);
                    },
                ),
            ),
        ];
    }

    onGridItemClick(rowId) {
        const { history } = this.context.router;
        history.location.pathname !== `${addonUiUrl}/${rowId}` && history.push(`${addonUiUrl}/${rowId}`);
    }

    render() {

        const gridProps = {
            data: extensionManagerSelectors.getAddons(this.props.extensionManager),
            isLoading: extensionManagerSelectors.isListLoading(this.props.extensionManager),
            onItemClick: this.onGridItemClick,
            ...this.getGridProps(),
        };

        return (<GridWithActions
            sticky={true}
            stickyTopOffset={254}
            stickyActionBarFreezePozition={42}
            stickyGridFreezePozition={102}
            actionGroups={this.getCommands()}
            gridType={"navigatable"}
            {...gridProps} />);
    }
}

AddonsGrid.propTypes = {

    translate: PropTypes.func.isRequired,
    extensionManager: PropTypes.object.isRequired,
    popupManagerActions: PropTypes.objectOf(PropTypes.func).isRequired,
    notificationManagerActions: PropTypes.objectOf(PropTypes.func).isRequired,
    extensionManagerActions: PropTypes.objectOf(PropTypes.func).isRequired,
};

AddonsGrid.contextTypes = {

    router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    roles: accountSelectors.getAccountRoles(state.account),
    translate: getTranslate(state.locale),
    extensionManager: state.extensionManager,
    notificationManager: state.notificationManager,
});

const mapDispatchToProps = (dispatch) => ({

    popupManagerActions: bindActionCreators(popupManagerActions, dispatch),
    notificationManagerActions: bindActionCreators(notificationManagerActions, dispatch),
    extensionManagerActions: bindActionCreators(extensionManagerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddonsGrid)
