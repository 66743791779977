import { all, call } from "redux-saga/effects";
import getAddonSaga from "./sagas/getAddonSaga";
import getListAddonsSaga from "./sagas/getListAddonsSaga";
import messageCenterSaga from "./sagas/messageCenterSaga";
import postPackageSaga from "./sagas/postPackageSaga";
import deletePackagesSaga from "./sagas/deletePackagesSaga";
import postConfigurationSaga from "./sagas/postConfigurationSaga";
import deleteConfigurationSaga from "./sagas/deleteConfigurationSaga";
import putPackageSaga from "./sagas/putPackageSaga";
import deleteExtensionStatusSaga from './sagas/deleteExtensionStatus';
import deleteAddonStatus from "./sagas/deleteAddonStatus";

export default function* () {
    yield all([
        call(getAddonSaga),
        call(getListAddonsSaga),
        call(messageCenterSaga),
        call(postPackageSaga),
        call(deletePackagesSaga),
        call(postConfigurationSaga),
        call(deleteConfigurationSaga),
        call(putPackageSaga),
        call(deleteExtensionStatusSaga),
        call(deleteAddonStatus),
    ]);
}
