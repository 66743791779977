import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Grid from "./Grid";
import "./expandable.css";

class ExpandableGrid extends Grid {

    constructor() {

        super();
        this.state = {
            expanded: [],
        };

        this.onExpandClick = this.onExpandClick.bind(this);
    }

    onExpandClick(id) {

        this.setState((state) => {

            if (state.expanded.includes(id))
            {
                return { expanded: state.expanded.filter(item => item !== id) }
            }
            else
            {
                return { expanded: [...state.expanded, id] };
            }
        });
    }

    getRow(row, index) {

        const { isRowExpandable } = this.props;
        if (isRowExpandable(row))
        {
            const { expanded } = this.state;
            const { renderExpandedData } = this.props;

            const expandedContent = expanded.includes(row.id)
                    ? (<div className="grid-expanded">{ renderExpandedData(row) }</div>)
                    : null;

            return (<div key={index} onClick={() => this.onRowClick(row.id)} className={ this.getRowClassName(row) }>
                    <div className="grid-columns">
                        { this.getRowCells(row) }
                    </div>
                    {expandedContent}
                </div>);
        }
        else
        {
            return (<div key={index} onClick={() => this.onRowClick(row.id)} className={ this.getRowClassName(row) }>
                    { this.getRowCells(row) }
                </div>);
        }
    }

    getRowCells(row) {

        const { isRowExpandable } = this.props;

        let expandButton = null;

        const rowExpandable = (row !== undefined && isRowExpandable(row));
        if (rowExpandable)
        {
            const rowExpanded = this.state.expanded.includes(row.id);
            const arrowClassName = classNames({
                "fa": true,
                "fa-angle-up": rowExpanded,
                "fa-angle-down": !rowExpanded,
            })
            expandButton = (<i className={arrowClassName} aria-hidden="true" onClick={() => this.onExpandClick(row.id)}></i>);
        }

        const rowCells = [
            <div key="-1" className="grid-cell grid-expand" onClick={e => { e.stopPropagation() }}>
                {expandButton}
            </div>];

        return rowCells.concat(super.getRowCells(row))
    }

    getRowClassName(row) {

        const { expanded } = this.state;

        const className = super.getRowClassName(row);
        return classNames(...className.split(" "), {
                "expandable": this.props.isRowExpandable(row),
                "expanded": expanded.includes(row.id),
            });
    }
}

ExpandableGrid.propTypes = {

    isRowExpandable: PropTypes.func.isRequired,
    renderExpandedData: PropTypes.func.isRequired,
};

export default ExpandableGrid
