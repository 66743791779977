import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./index.scss";

class NoData extends PureComponent {

    render() {

        const {
            iconClassName,
            image,
            title,
        } = this.props;

        return (
            <div className="no-data">
                {
                    image === undefined
                        ? (<i className={`fa ${iconClassName}`} aria-hidden="true" />)
                        : (<img src={image} alt="" />)
                }
                <h2>{title}</h2>
                {this.props.children}
            </div>
        );
    }
}

NoData.propTypes = {

    iconClassName: PropTypes.string.isRequired,
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
}

NoData.defaultProps = {

    iconClassName: "fa-file-o",
}

export default NoData
