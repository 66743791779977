import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./index.css";

class Checkbox extends PureComponent {

    constructor(props) {

        super(props);

        const { defaultChecked } = props;
        this.state = {
            isChecked: defaultChecked,
        };

        this.onCheckboxClick = this.onCheckboxClick.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
    }

    componentWillReceiveProps(props) {

        const { defaultChecked } = props;
        this.setState({ isChecked: defaultChecked });
    }

    onCheckboxClick(e) {

        this.setState(({ isChecked }) => (
            {
              isChecked: !isChecked,
            }
        ));

        const { onClick } = this.props;
        if (onClick !== undefined)
        {
            onClick(e);
        }
    }

    onCheckboxChange(e) {

        const { onChange } = this.props;
        if (onChange !== undefined)
        {
            onChange(e);
        }
    }

    render() {

        const { isChecked } = this.state;

        return (<label className="checkbox" onClick={e => { e.stopPropagation() }}>
                <input type="checkbox" checked={isChecked} onChange={this.onCheckboxChange} />
                <span className="checkbox-checkmark" onClick={this.onCheckboxClick}></span>
            </label>);
    }
}

Checkbox.propTypes = {

    defaultChecked: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
};

Checkbox.defaultProps = {

    defaultChecked: false,
};

export default Checkbox
