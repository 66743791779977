import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getTranslate } from "react-localize-redux";
import { ActivityOverlay, NoData, Can } from "../../../../components";
import AddonsGrid from "./AddonsGrid";
import { actions as extensionManagerActions } from "../../../ExtensionManager";
import { selectors as extensionManagerSelectors } from "../../../ExtensionManager";
import { formatResourceMessage } from "../../../../utils";
import { selectors as accountSelectors, ACTIONS } from '../../../PrivateRoute';

class ContentProvider extends PureComponent {

    constructor() {

        super();
        this.loadAddons = this.loadAddons.bind(this);
    }

    componentDidMount() {

        const isListLoading = extensionManagerSelectors.isListLoading(this.props.extensionManager);
        if (isListLoading === false) {
            this.loadAddons();
        }
    }

    loadAddons() {

        const { translate } = this.props;
        const { getListAddons } = this.props.extensionManagerActions;

        getListAddons({ failMessage: translate("manageView.notifications.getListAddonsFailed") });
    }

    render() {

        const { translate, roles } = this.props;

        const addonsCount = extensionManagerSelectors.getAddonsCount(this.props.extensionManager);
        const isListLoading = extensionManagerSelectors.isListLoading(this.props.extensionManager);
        const isUploading = extensionManagerSelectors.isUploading(this.props.extensionManager)
        const isListLoadFailed = extensionManagerSelectors.isListLoadFailed(this.props.extensionManager);

        if (isListLoadFailed === true && isListLoading === false) {
            const descriptionElements = formatResourceMessage(
                translate("manageView.loadFailed.message"),
                (s, i) => (<span key={i} className="link" onClick={this.loadAddons}>{translate(`manageView.loadFailed.${s}`)}</span>));

            return (
                <NoData iconClassName="fa-exclamation-circle" title={translate("manageView.loadFailed.title")}>
                    <p>{descriptionElements}</p>
                </NoData>
            );
        }
        else if ((isUploading || isListLoading) && addonsCount === 0) {
            const { translate } = this.props;

            let loadingText;

            switch (true) {
                case isListLoading:
                    loadingText = <p>{translate("manageView.activityIndicator.loadingPackages")}</p>;
                    break;
                case isUploading:
                    loadingText = <p>{translate("manageView.activityIndicator.uploadingPackage")}</p>;
                    break;
                default:
                    loadingText = null;
            }

            return (
                <ActivityOverlay>{loadingText}</ActivityOverlay>);
        }
        else if (addonsCount === 0) {
            const { onBrowseLocallyClick } = this.props;

            const descriptionElements = formatResourceMessage(
                translate("manageView.noExtensions.messageBrowseLocally"),
                (s, i) => (<span key={i} className="link" onClick={onBrowseLocallyClick}>{translate(`manageView.noExtensions.${s}`)}</span>),
                (s, i) => (<NavLink key={i} to="/browse">{translate(`manageView.noExtensions.${s}`)}</NavLink>));

            return (
                <NoData iconClassName="fa-file-archive-o" title={translate("manageView.noExtensions.title")}>
                    <Can roles={roles} action={ACTIONS.addon.create}>
                        <p>{descriptionElements}</p>
                    </Can>
                </NoData>
            );
        }

        return (<AddonsGrid />);
    }
}

ContentProvider.propTypes = {

    translate: PropTypes.func.isRequired,
    extensionManager: PropTypes.object.isRequired,
    onBrowseLocallyClick: PropTypes.func.isRequired,
    extensionManagerActions: PropTypes.objectOf(PropTypes.func).isRequired,
};

const mapStateToProps = (state) => ({
    roles: accountSelectors.getAccountRoles(state.account),
    translate: getTranslate(state.locale),
    extensionManager: state.extensionManager,
});

const mapDispatchToProps = (dispatch) => ({

    extensionManagerActions: bindActionCreators(extensionManagerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentProvider)
