import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { TransitionGroup } from "react-transition-group";
import AnimatedPopup from "./components/AnimatedPopup";
import { actions as popupManagerActions } from "./reducer";
import { selectors as popupManagerSelectors } from "./reducer";
import "./style.css";

class PopupManager extends PureComponent {

    render() {

        const {
            activePopup,
            popupManagerActions,
        } = this.props;

        return (
            <div className="popup-manager">
                <TransitionGroup>
                    { activePopup !== null ?
                        <AnimatedPopup
                            {...activePopup}
                            onClose={popupManagerActions.archivePopup}
                        /> : null }
                </TransitionGroup>
            </div>
        );
    }
}

PopupManager.propTypes = {

    activePopup: PropTypes.shape(AnimatedPopup.propTypes.popup),
    popupManagerActions: PropTypes.objectOf(PropTypes.func).isRequired,
};

const mapStateToProps = (state) => ({

    activePopup: popupManagerSelectors.getActivePopup(state.popupManager),
});

const mapDispatchToProps = (dispatch) => ({

    popupManagerActions: bindActionCreators(popupManagerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupManager)
