import { Button, Icon, Upload } from 'antd';
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { ActionBar, ActivityOverlay, Badge, NoData } from "../../../../components";
import Can, { isUnable } from "../../../../components/Can";
import { addonUiUrl } from '../../../../configuration/config';
import { createUrlWithToken } from "../../../../openid/request";
import { createCommand, download, formatDateString, formatResourceMessage, groupCommands } from "../../../../utils";
import { actions as extensionManagerActions, selectors as extensionManagerSelectors } from "../../../ExtensionManager";
import { actions as notificationManagerActions, selectors as notificationManagerSelectors } from "../../../NotificationManager";
import { actions as popupManagerActions } from "../../../PopupManager";
import { ACTIONS, selectors as accountSelectors } from '../../../PrivateRoute';
import defaultIcon from '../extension.128.svg';
import ExtensionsGrid from "./ExtensionsGrid";
import noDataImage from "./no-data.svg";

class ContentProvider extends PureComponent {

    constructor() {
        super();
        this.getAddOnCommands = this.getAddOnCommands.bind(this);
        this.onDeleteNavigate = this.onDeleteNavigate.bind(this);
        this.onClickDeleteConfig = this.onClickDeleteConfig.bind(this);
        this.onClickResetAddonStatus = this.onClickResetAddonStatus.bind(this);
    }

    componentDidMount() {
        const { getAddon } = this.props.extensionManagerActions;
        getAddon({ id: this.props.addonId });
    }

    getAddOnCommands() {

        const { addonId, translate, roles, notificationManager } = this.props;
        const { registerPopup } = this.props.popupManagerActions;
        const { registerNotification } = this.props.notificationManagerActions;
        const { deletePackages } = this.props.extensionManagerActions;

        const addon = extensionManagerSelectors.getAddonById(this.props.extensionManager, addonId);

        return [
            groupCommands(
                createCommand(
                    "download",
                    translate("manageView.actionBar.download"),
                    (selection) => download(
                        selection.map(() => ({
                            url: createUrlWithToken(addon.downloadUri),
                            fileName: `${addon.name}.zip`
                        }),
                        ),
                        () => registerNotification({
                            type: "error",
                            title: translate("manageView.notifications.downloadFailed", { packageName: addon.name })
                        }),
                        null,
                        () => registerNotification({
                            type: "error",
                            title: notificationManagerSelectors.getAuthMessage(notificationManager),
                        })
                    ),
                    undefined,
                    isUnable(roles, ACTIONS.addon.download)
                ),
            ),
            groupCommands(
                createCommand(
                    "delete",
                    translate("manageView.actionBar.remove"),
                    (selection) => {
                        registerPopup({
                            icon: "fa-question",
                            danger: true,
                            title: translate("manageView.deleteConfirmation.title", { addonName: addon.name }),
                            message: translate("manageView.deleteConfirmation.message"),
                            actionLabel: translate("manageView.deleteConfirmation.remove"),
                            cancelLabel: translate("manageView.deleteConfirmation.cancel"),
                            actionHandler: () => deletePackages({
                                extensionPackages: selection,
                                successMessage: translate("manageView.notifications.removeSuccess", { packageName: addon.name }),
                                failMessage: translate("manageView.notifications.removeFailed", { packageName: addon.name }),
                                callback: () => this.onDeleteNavigate()
                            })
                        });
                    },
                    undefined,
                    isUnable(roles, ACTIONS.addon.delete)
                ),
            )
        ];
    }

    onDeleteNavigate() {
        this.props.history.push(addonUiUrl);
    }

    getUploadAddonProps() {
        const { putPackage } = this.props.extensionManagerActions;
        const { registerPopup } = this.props.popupManagerActions;
        const { addonId, translate } = this.props;
        const addon = extensionManagerSelectors.getAddonById(this.props.extensionManager, addonId);

        return {
            name: 'configuration',
            showUploadList: false,
            beforeUpload(file) {
                registerPopup({
                    icon: "fa-question",
                    danger: true,
                    title: translate("manageView.updateConfirmation.title", { addonName: addon.name }),
                    message: (addon.requireConfiguration === 'Yes' || addon.requireConfiguration === 'Optional') && addon.configuration
                        ? translate("manageView.updateConfirmation.configurationWarningMessage", { message: "<br />" + translate("manageView.updateConfirmation.message") })
                        : translate("manageView.updateConfirmation.message"),
                    actionLabel: translate("manageView.updateConfirmation.remove"),
                    cancelLabel: translate("manageView.updateConfirmation.cancel"),
                    actionHandler: () => putPackage({
                        extensionPackage: file,
                        id: addonId,
                        successMessage: {
                            modified: translate("manageView.notifications.packageReuploadSuccess"),
                            notModified: translate("manageView.notifications.packageReuploadSuccessNotModified")
                        },
                        failMessage: translate("manageView.notifications.packageReuploadFailed", { packageName: addon.name })
                    })
                });

                return false;
            }
        };
    }

    getUploadProps() {
        const { postConfiguration } = this.props.extensionManagerActions;
        const { registerPopup } = this.props.popupManagerActions;
        const { addonId, translate } = this.props;
        const addon = extensionManagerSelectors.getAddonById(this.props.extensionManager, addonId);

        return {
            name: 'configuration',
            showUploadList: false,
            accept: '.json',
            beforeUpload(file) {
                if (addon.configuration) {
                    registerPopup({
                        icon: "fa-question",
                        danger: true,
                        title: translate("addonView.configuration.upload.confirmTitle"),
                        message: translate("addonView.configuration.upload.confirmMessage"),
                        actionLabel: translate("addonView.configuration.upload.confirmYes"),
                        cancelLabel: translate("addonView.configuration.upload.confirmNo"),
                        actionHandler: () => postConfiguration({
                            configurationFile: file,
                            addonId,
                            successMessage: translate("addonView.configuration.upload.messageSuccess"),
                            failMessage: translate("addonView.configuration.upload.messageFailed", { packageName: addon.name })
                        })
                    });
                } else {
                    postConfiguration({
                        configurationFile: file,
                        addonId,
                        successMessage: translate("addonView.configuration.upload.messageSuccess"),
                        failMessage: translate("addonView.configuration.upload.messageFailed", { packageName: addon.name })
                    });
                }
                return false;
            }
        };
    }

    onClickDeleteConfig() {
        const { deleteConfiguration } = this.props.extensionManagerActions;
        const { registerPopup } = this.props.popupManagerActions;
        const { addonId, translate } = this.props;
        const addon = extensionManagerSelectors.getAddonById(this.props.extensionManager, addonId);

        registerPopup({
            icon: "fa-question",
            danger: true,
            title: translate("addonView.configuration.delete.confirmTitle"),
            message: translate("addonView.configuration.delete.confirmMessage"),
            actionLabel: translate("addonView.configuration.delete.confirmYes"),
            cancelLabel: translate("addonView.configuration.delete.confirmNo"),
            actionHandler: () => deleteConfiguration({
                addonId,
                successMessage: translate("addonView.configuration.delete.messageSuccess"),
                failMessage: translate("addonView.configuration.delete.messageFailed", { packageName: addon.name })
            })
        });
    }

    onClickResetAddonStatus() {
        const { registerPopup } = this.props.popupManagerActions;
        const { translate, extensionManagerActions, addonId } = this.props;
        const addon = extensionManagerSelectors.getAddonById(this.props.extensionManager, addonId);

        registerPopup({
            icon: "fa-question",
            danger: true,
            title: translate("addonView.addonStatus.confirmTitle"),
            message: translate("addonView.addonStatus.confirmMessage"),
            actionLabel: translate("addonView.addonStatus.confirmYes"),
            cancelLabel: translate("addonView.addonStatus.confirmNo"),
            actionHandler: () => extensionManagerActions.deleteAddonStatus({
                addonId,
                successMessage: translate("addonView.addonStatus.resetSuccess"),
                failMessage: translate("addonView.addonStatus.resetFail", { addonName: addon.name }),
            }),
        });
    }

    render() {

        const { translate, addonId, roles, notificationManager } = this.props;
        let addonName, addonInfo, addonStatus, actionBar, addonAuthor, extensionsCountBadge, data, configSection = null;

        const isAddonLoading = extensionManagerSelectors.isAddonLoading(this.props.extensionManager);
        const isAddonLoadFailed = extensionManagerSelectors.isAddonLoadFailed(this.props.extensionManager);
        const addon = extensionManagerSelectors.getAddonById(this.props.extensionManager, addonId);
        const extensionsCount = extensionManagerSelectors.getAddonExtensionsCountById(this.props.extensionManager, addonId);
        const { registerNotification } = this.props.notificationManagerActions;

        if (isAddonLoadFailed === true && isAddonLoading === false) {
            data = (
                <NoData iconClassName="fa-exclamation-circle" title={translate("manageView.addonLoadFailed.title")}>
                    <p>{translate("manageView.addonLoadFailed.message")}</p>
                </NoData>);
        }
        else if (isAddonLoading === true && extensionsCount === null) {
            data = (
                <ActivityOverlay>
                    <p>{translate("manageView.activityIndicator.loadingPackage")}</p>
                </ActivityOverlay>);
        }
        else if (addon === null) {
            const descriptionElements = formatResourceMessage(
                translate("manageView.addonNotFound.message"),
                (s, i) => (<span key={i} className="link" onClick={this.props.goBackHandler}>{translate(`manageView.addonNotFound.${s}`)}</span>));

            data = (
                <NoData image={noDataImage} title={translate("manageView.addonNotFound.title")}>
                    <p>{descriptionElements}</p>
                </NoData>);
        }
        else {
            actionBar = (
                <div className="content-provider__actions">
                    <ActionBar actionGroups={this.getAddOnCommands()} selection={[addon.id]} />
                    <Upload className="content-provider__sidebar-section-button" {...this.getUploadAddonProps()}>
                        <Button disabled={isUnable(roles, ACTIONS.addon.update)} className="btn" type="primary"><Icon type="upload" />{translate("manageView.actionBar.update")}</Button>
                    </Upload>
                </div>


            );
            addonName = (<h2 className="content-provider__addon-name">{addon.name} (v.{addon.version})</h2>);
            addonAuthor = (<div className="content-provider__addon-author">
                <span>{translate("addonView.byAuthor")} </span>
                <span className="content-provider__addon-author-text">{addon.author}</span>
            </div>);
            let iconPath = addon.downloadIconUri || defaultIcon;
            addonInfo = (<div className="content-provider__addon-description">
                <div className="content-provider__addon-image-wrap"><img className="content-provider__addon-image" src={iconPath} alt={addonName} /></div>
                <div className="content-provider__addon-text">{addon.description}</div>
            </div>);

            let statusMessage, modifier, icon = null;
            let theme = false;
            switch (addon.status) {
                case 'Pending':
                    statusMessage = translate("addonView.addonStatus.messagePending");
                    modifier = "grey";
                    icon = "loading";
                    break;
                case 'Fail':
                    statusMessage = translate("addonView.addonStatus.failed");
                    modifier = "error";
                    icon = "exclamation-circle";
                    theme = "filled";
                    break;
                case 'WaitingConfiguration':
                    statusMessage = translate("manageView.addOnsList.waitingConfiguration");
                    modifier = "error";
                    icon = "exclamation-circle";
                    theme = "filled";
                    break;
                case 'Success':
                default:
                    statusMessage = translate("addonView.addonStatus.messageSuccess");
                    modifier = "success";
                    icon = "check-circle";
                    theme = "filled";
            }

            addonStatus = (<div className="content-provider__sidebar-section">
                <div className="content-provider__sidebar-section-title-wrapper">
                    <h4 className="content-provider__sidebar-section-title">{translate("addonView.addonStatus.sectionTitle")}</h4>
                    {addon.status !== "Fail" && <p className={`content-provider__sidebar-section-status content-provider__sidebar-section-status--${modifier}`}><Icon type={icon} theme={theme} /> {statusMessage}</p>}
                </div>

                {
                    addon.status === "Fail"
                    && <p className={`content-provider__sidebar-section-message content-provider__sidebar-section-message--${modifier}`}><Icon type={icon} theme={theme} /> {translate("addonView.addonStatus.messageFailed")}</p>
                }

                {
                    !!addon.statusReports.length
                    && <div className='content-provider__sidebar-section-message'>
                        {addon.statusReports.filter(report => { return report.status === 'Fail'; }).map((report, i) => {
                            return (
                                <p key={i} className={`status-description status-description--${modifier} content-provider__sidebar-section-message content-provider__sidebar-section-message--${modifier}`}>
                                    <Icon className={`status-description__icon status-description__icon--${modifier}`} type='exclamation-circle' theme='filled' />
                                    <span className="status-description__text">{report.message} (Environment: {report.environment}, Client Identity: {report.clientIdentity}, Process: {report.process})</span>
                                </p>
                            );
                        })}
                    </div>
                }

            </div>);

            extensionsCountBadge = (<h3 className="content-provider__extensions-title">{translate("addonView.extensions.name")}<Badge className="content-provider__extensions-badge" size="normal">{extensionsCount}</Badge></h3>);

            data = (<ExtensionsGrid addonId={addon.id} />);

            configSection = addon.requireConfiguration !== 'No' &&
                (<div className="content-provider__sidebar-section">
                <h4 className="content-provider__sidebar-section-title">{translate("addonView.configuration.sectionTitle")}<span className="ask"> {addon.requireConfiguration === 'Yes' ? "*" :""}</span></h4>
                    {addon.requireConfiguration === 'Yes' && !addon.configuration
                    ? (<p className="content-provider__sidebar-section-message content-provider__sidebar-section-message--error"><Icon type="exclamation-circle" theme="filled" /> {translate("addonView.configuration.addWarning")}</p>)
                        : addon.configuration && (<div className="content-provider__config">
                        <button
                            disabled={isUnable(roles, ACTIONS.config.download)}
                            title={translate("addonView.configuration.download.title", { packageName: addon.name })}
                            className="content-provider__config-link"
                            onClick={() => download([{ url: createUrlWithToken(addon.downloadConfigurationUri), fileName: addon.configuration.fileName, method: "get"}],
                            () => {
                            registerNotification({
                                type: "error",
                                title: translate("addonView.configuration.download.messageFailed", { packageName: addon.name})
                            })
                            },
                            null,
                            () => registerNotification({
                                type: "error",
                                title: notificationManagerSelectors.getAuthMessage(notificationManager),
                            }))} >
                                <Can roles={roles} action={ACTIONS.config.download}><Icon type="download" /></Can>
                                {addon.configuration.fileName}
                        </button>
                        <span className="content-provider__config-age">{formatDateString(addon.configuration.uploadedAt)}</span>
                        </div>)
                    }

                    <div className="content-provider__sidebar-section-buttons">
                        <Upload className="content-provider__sidebar-section-button" {...this.getUploadProps()}>
                        <Button disabled={isUnable(roles, ACTIONS.config.create)} className="btn btn--add" type="primary"><Icon type="plus" />{translate("addonView.configuration.upload.button")}</Button>
                        </Upload>
                        {addon.configuration && (
                        <Button onClick={this.onClickDeleteConfig} disabled={isUnable(roles, ACTIONS.config.delete)} className="content-provider__sidebar-section-button btn btn--delete" type="primary"><Icon type="delete" />{translate("addonView.configuration.delete.button")}</Button>
                        )}
                    </div>
                </div >)
        }

        return (<div className="content-provider">
            <div className="content-provider__content">
                <Link to={addonUiUrl}>
                        <Button
                            className="btn btn--back"
                            type="primary"
                            icon="left"
                        >{translate("addonView.backButton")}</Button>
                </Link>

                <section className="content-provider__info">
                    {addonName}
                    {addonAuthor}
                    {addonInfo}
                </section>

                <section className="content-provider__extensions">
                    {extensionsCountBadge}
                    {data}
                </section>
            </div>
            <div className="content-provider__sidebar">
                {actionBar}
                {addonStatus}
                {configSection}
            </div>
        </div>);
    }
}

ContentProvider.propTypes = {

    translate: PropTypes.func.isRequired,
    addonId: PropTypes.number.isRequired,
    extensionManager: PropTypes.object.isRequired,
    popupManagerActions: PropTypes.objectOf(PropTypes.func).isRequired,
    extensionManagerActions: PropTypes.objectOf(PropTypes.func).isRequired,
    notificationManagerActions: PropTypes.objectOf(PropTypes.func).isRequired,
};

const mapStateToProps = (state) => ({
    roles: accountSelectors.getAccountRoles(state.account),
    translate: getTranslate(state.locale),
    extensionManager: state.extensionManager,
    notificationManager: state.notificationManager,
});

const mapDispatchToProps = (dispatch) => ({

    popupManagerActions: bindActionCreators(popupManagerActions, dispatch),
    extensionManagerActions: bindActionCreators(extensionManagerActions, dispatch),
    notificationManagerActions: bindActionCreators(notificationManagerActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentProvider))
