import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "font-awesome/css/font-awesome.css";
import "./index.scss";

class Button extends PureComponent {

    render() {

        const {
            icon,
            iconPosition,
            label,
            onClick,
            disabled,
            type,
            size,
        } = this.props;

        const buttonProps = {

            onClick,
            className: classNames({
                "button": true,
                "primary": type === "primary",
                "cta": type === "cta",
                "ghost": type === "ghost",
                "ghost-inverted": type === "ghost-inverted",
                "ghost-inverted-fill": type === "ghost-inverted-fill",
                "big": size === "big",
                "small": size === "small",
            }),
        };

        if (disabled === true) {
            buttonProps.disabled = disabled;
        }

        const iconElement = icon !== undefined ?
            (<i key="icon" className={`icon fa ${icon}`} aria-hidden="true" />) :
            null;

        const labelElement = label !== undefined ?
            (<span key="label" className="label">{label}</span>) :
            null;

        const buttonChildren = iconPosition === "left" ?
            [iconElement, labelElement] :
            [labelElement, iconElement];

        return (
            <button {...buttonProps}>
                {buttonChildren}
            </button>
        );
    }
}

Button.propTypes = {

    type: PropTypes.oneOf(["normal", "primary", "cta", "ghost", "ghost-inverted", "ghost-inverted-fill"]).isRequired,
    size: PropTypes.oneOf(["normal", "big", "small"]).isRequired,
    label: PropTypes.string,
    icon: PropTypes.string,
    iconPosition: PropTypes.oneOf(["left", "right"]).isRequired,
    disabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {

    type: "normal",
    size: "normal",
    iconPosition: "left",
    disabled: false,
};

export default Button
