import { UserManager, Log } from 'oidc-client';
import { addonUiUrl } from "../configuration/config"
import { KEY_OPENID_SETTINGS } from './constants';

function getDefaultUserManagerSettings() {
    const openIdConfiguration = window.openIdConfiguration;
    const location = window.location;
    const settings = {
        authority: openIdConfiguration.authority,
        client_id: openIdConfiguration.clientId,
        redirect_uri: `${location.protocol}//${location.host}${addonUiUrl}/signin-oidc`,
        response_type: 'code',
        scope: 'openid profile',
        filterProtocolClaims: true,
        loadUserInfo: true,
        automaticSilentRenew: true
    };
    return settings;
}

function getOidcSettings() {
    const openIdSettingsString = sessionStorage.getItem(KEY_OPENID_SETTINGS);
    let settings;
    if (openIdSettingsString === null) {
        settings = getDefaultUserManagerSettings();
        const settingsString = JSON.stringify(settings);
        sessionStorage.setItem(KEY_OPENID_SETTINGS, settingsString);
    } else {
        settings = JSON.parse(openIdSettingsString);
    }
    return settings;
}

export function createOidcUserManager() {
    let settings;
    if (window.openIdConfiguration && window.openIdConfiguration.isEnabled) {
        settings = getOidcSettings();
    } else {
        settings = {};
    }
    if (settings.enableOidcClientJsLogging) {
        Log.logger = console;
        Log.level = Log.DEBUG;
    }
    return new UserManager(settings);
}
