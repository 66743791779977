import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getTranslate } from "react-localize-redux";
import ContentProvider from "./ContentProvider";
import { Badge, UploadButton } from "../../../../components";
import { actions as extensionManagerActions } from "../../";
import { actions as notificationManagerActions } from "../../../NotificationManager";
import { selectors as extensionManagerSelectors } from "../../../ExtensionManager";
import { selectors as accountSelectors, ACTIONS } from '../../../PrivateRoute';
import "./style.scss";
import { isUnable } from "../../../../components/Can";

class ManageView extends PureComponent {

    constructor() {

        super();
        this.onFilesSelected = this.onFilesSelected.bind(this);
        this.onFileNotSupported = this.onFileNotSupported.bind(this);
        this.onBrowseLocallyClick = this.onBrowseLocallyClick.bind(this);
    }

    componentWillMount() {

        if (window.pageYOffset > 200) {
            window.scrollTo(0, 200);
        }
    }

    onFilesSelected(files) {

        const { translate } = this.props;
        const { postPackage } = this.props.extensionManagerActions;

        postPackage({
            extensionPackage: files[0],
            successMessage: {
                new: translate("manageView.notifications.packageUploadSuccess"),
                replace: translate("manageView.notifications.packageReuploadSuccess"),
                notModified: translate("manageView.notifications.packageReuploadSuccessNotModified")
            },

            failMessage: {
                new: translate("manageView.notifications.packageUploadFailed"),
                replace: translate("manageView.notifications.packageReuploadFailed")
            },
        });
    }

    onFileNotSupported(files) {

        const { translate } = this.props;
        const { registerNotification } = this.props.notificationManagerActions;

        registerNotification({
            type: "error",
            title: translate("manageView.notifications.fileExtensionNotSupported"),
            messages: files.map(file => `"${file.name}"`)
        });
    }

    onBrowseLocallyClick() {

        this.uploadButton.click();
    }

    render() {

        const { translate, roles } = this.props;

        const uploadButtonProps = {
            ref: button => this.uploadButton = button,
            icon: "plus",
            label: translate("manageView.uploadButton"),
            allowedExtensions: [".zip", "application/zip", "application/octet-stream"],
            onFilesSelected: this.onFilesSelected,
            onFileNotSupported: this.onFileNotSupported,
        };

        const addonsCount = extensionManagerSelectors.getAddonsCount(this.props.extensionManager);
        const addonsBadge = extensionManagerSelectors.isListLoadFailed(this.props.extensionManager) === false
            ? (<Badge size="big">{addonsCount}</Badge>)
            : null;

        return (
            <div className="manage-view transition-item">
                <UploadButton {...uploadButtonProps} disabled={isUnable(roles, ACTIONS.addon.create)} />
                <h2>{translate("manageView.title")}{addonsBadge}</h2>
                <ContentProvider onBrowseLocallyClick={this.onBrowseLocallyClick} />
            </div>
        );
    }
}

ManageView.propTypes = {

    translate: PropTypes.func.isRequired,
    extensionManager: PropTypes.object.isRequired,
    extensionManagerActions: PropTypes.objectOf(PropTypes.func).isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({

    translate: getTranslate(state.locale),
    extensionManager: state.extensionManager,
    roles: accountSelectors.getAccountRoles(state.account),
});

const mapDispatchToProps = (dispatch) => ({

    extensionManagerActions: bindActionCreators(extensionManagerActions, dispatch),
    notificationManagerActions: bindActionCreators(notificationManagerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageView)
