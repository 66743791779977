import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "antd";
import "./index.css";

class UploadButton extends PureComponent {

    constructor() {

        super();
        this.click = this.click.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onFilesSelected = this.onFilesSelected.bind(this);
    }

    click() {

        this.fileInput.click();
    }

    getFileExtension(fileName) {

        let fileExtension = "";
        if (fileName.lastIndexOf(".") > 0) {
            fileExtension = fileName.substring(fileName.lastIndexOf("."), fileName.length);
        }

        return fileExtension;
    }

    onButtonClick() {

        this.click();
    }

    onFilesSelected() {

        // Eliminate IE11 second phantom click
        if (this.fileInput.files === null || this.fileInput.files.length === 0) return;

        const {
            allowedExtensions,
            onFileNotSupported,
            onFilesSelected
        } = this.props;

        let notSupportedFiles = [];
        if (onFileNotSupported !== undefined && allowedExtensions.length > 0) {
            const files = Array.from(this.fileInput.files);
            notSupportedFiles = files.filter(file => {
                const fileExtension = this.getFileExtension(file.name);
                return !allowedExtensions.includes(fileExtension);
            });
        }

        if (notSupportedFiles.length === 0) {
            onFilesSelected(this.fileInput.files);
        }
        else {
            onFileNotSupported !== undefined && onFileNotSupported(notSupportedFiles);
        }

        this.fileInput.value = null;
    }

    render() {

        const {
            label,
            allowedExtensions,
            multiple,
            icon,
            disabled
        } = this.props;

        const inputOptions = {
            ref: input => { this.fileInput = input; },
            onChange: this.onFilesSelected,
            type: "file",
        };

        if (multiple) {
            inputOptions.multiple = multiple;
        }

        if (allowedExtensions.length) {
            inputOptions.accept = allowedExtensions.join(",");
        }

        return (
            <div className="upload-button">
                <input {...inputOptions} />
                <Button
                    className="btn btn--upload"
                    disabled={disabled}
                    onClick={this.onButtonClick}
                    type="primary">
                        <Icon type={icon} />{label}
                </Button>
            </div>
        );
    }
}

UploadButton.propTypes = {

    icon: PropTypes.string,
    label: PropTypes.string,
    onFilesSelected: PropTypes.func.isRequired,
    onFileNotSupported: PropTypes.func,
    multiple: PropTypes.bool.isRequired,
    allowedExtensions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

UploadButton.defaultProps = {

    multiple: false,
    allowedExtensions: [],
}

export default UploadButton
