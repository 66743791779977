import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { ButtonsGroup } from "../";
import { Button } from "antd";
import "./index.scss";

class ActionBar extends PureComponent {

    getAvailableActions() {

        const { actionGroups, selection } = this.props;

        return actionGroups.map((actionGroup, i) => {

            const availableActions = actionGroup.filter(command => {

                return command.isAvailable === undefined || command.isAvailable(selection) === true
            });

            if (availableActions.length > 0) {
                return (<ButtonsGroup key={i}>{availableActions.map((command, i) => {

                    return (
                        <Button
                            disabled={command.isDisabled}
                            className="btn"
                            key={i}
                            icon={command.icon}
                            onClick={() => { command.execute(selection) }}>
                                {command.label}
                        </Button>
                    );

                })}</ButtonsGroup>);
            }

            return null;
        });
    }

    render() {

        const { style } = this.props;
        return (<div className={`action-bar ${this.props.className}`} style={style}>{this.getAvailableActions()}</div>);
    }
}

ActionBar.propTypes = {

    style: PropTypes.object.isRequired,
    actionGroups: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                icon: PropTypes.string,
                execute: PropTypes.func.isRequired,
                isAvailable: PropTypes.func,
                isDisabled: PropTypes.bool,
            })
        )
    ).isRequired,
    selection: PropTypes.arrayOf(PropTypes.number).isRequired,
};

ActionBar.defaultProps = {

    style: {},
};

export default ActionBar
