import React from "react";

const tokenGenerator = function*(inputString) {

	const placeHoldersRegEx = /\{\d+\}/g;

	let index = 0;
	while (true)
	{
		const result = placeHoldersRegEx.exec(inputString);

		if ((!result || index !== result.index) && index !== inputString.length)
		{
			yield {
				type: "string",
				value: inputString.slice(index, result ? result.index : inputString.length)
			};
		}

		if (result)
		{
			const placeHolder = result[0];
			yield {
				type: "translatableKey",
				value: placeHolder
			}

			index = result.index + placeHolder.length;
		}
		else
		{
			break;
		}
	}
};

const placeHolderToNumber = (token) => Number(token.slice(1, token.length - 1));

export default function formatResourceMessage(complexTranslatedMessage, ...params) {

	return [...tokenGenerator(complexTranslatedMessage)].map(

		(ti, i) => {

			switch (ti.type)
			{
				case "string":
					return (<span key={i}>{ ti.value }</span>);

				case "translatableKey":
					return params[placeHolderToNumber(ti.value)](ti.value, i);

				default:
					return null;
			}
		});
}
