import { takeEvery, select, put, fork } from "redux-saga/effects";
import {
    actions as popupManagerActions,
    types as popupManagerTypes,
    selectors as popupManagerSelectors,
} from "./reducer";

const processDisplayPopup = function*(popup) {

    const activePopup = yield select(state => popupManagerSelectors.getActivePopup(state.popupManager));
    if (activePopup !== null)
    {
        throw new Error("Cannot display more than one popup at the same time");
    }

    yield put(popupManagerActions.displayPopup(popup));
}

const processPopupActivation = function*() {

    const pendingPopups = yield select(state => popupManagerSelectors.getPendingPopups(state.popupManager));
    if (pendingPopups.length === 0)
    {
        return;
    }

    const activePopup = yield select(state => popupManagerSelectors.getActivePopup(state.popupManager));
    if (activePopup !== null)
    {
        return;
    }

    yield fork(processDisplayPopup, pendingPopups[0]);
}

export default function*() {

    yield takeEvery(popupManagerTypes.REGISTER_POPUP, processPopupActivation);
    yield takeEvery(popupManagerTypes.ARCHIVE_POPUP, processPopupActivation);
}
