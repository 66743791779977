import { combineReducers } from "redux";

import { localeReducer } from "react-localize-redux";
import { reducer as popupManagerReducer } from "../modules/PopupManager";
import { reducer as notificationManagerReducer } from "../modules/NotificationManager";
import { reducer as extensionManagerReducer } from "../modules/ExtensionManager";
import { reducer as accountReducer } from "../modules/PrivateRoute";

export default () => combineReducers({

    locale: localeReducer,
    popupManager: popupManagerReducer,
    notificationManager: notificationManagerReducer,
    extensionManager: extensionManagerReducer,
    account: accountReducer,
});
