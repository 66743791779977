import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Sticky } from "react-sticky";
import { ActionBar, Grid, SelectableGrid, NavigatableGrid, ExpandableGrid } from "../";

class GridWithActions extends PureComponent {

    constructor() {

        super();

        this.state = {
            selection: [],
        };

        this.onGridSelectionChanged = this.onGridSelectionChanged.bind(this);
    }

    onGridSelectionChanged(selection) {

        const { onSelectionChanged } = this.props;
        this.setState({ selection });

        if (onSelectionChanged !== undefined)
        {
            onSelectionChanged(selection);
        }
    }

    render() {

        const {
            sticky,
            stickyTopOffset,
            stickyActionBarFreezePozition,
            stickyGridFreezePozition,
            actionGroups,
            gridType,
            ...others
        } = this.props;

        const { selection } = this.state;

        const actionBar = sticky ? (<Sticky topOffset={stickyTopOffset}>
                {params => {
                    const style = {...params.style};
                    style.top = stickyActionBarFreezePozition;
                    style.zIndex = 1;
                    return (<ActionBar style={style} actionGroups={actionGroups} selection={selection} />);
                }}
            </Sticky>) :
            (<ActionBar actionGroups={actionGroups} selection={selection} />);

        const gridProps = {...others};
        gridProps.onSelectionChanged = this.onGridSelectionChanged;

        if (sticky === true)
        {
            gridProps.sticky = true;
            gridProps.stickyFreezePozition = stickyGridFreezePozition;
            gridProps.stickyTopOffset = stickyTopOffset;
        }

        let grid = null;
        switch (gridType)
        {
            case "selectable":
                grid = (<SelectableGrid {...gridProps} />);
                break;

            case "navigatable":
                grid = (<NavigatableGrid {...gridProps} />);
                break;

            case "expandable":
                grid = (<ExpandableGrid {...gridProps} />);
                break;

            default:
                grid = <Grid {...gridProps} />;
        }

        return (<div className="grid-with-actions">
                {actionBar}
                {grid}
            </div>);
    }
}

GridWithActions.propTypes = {

    sticky: PropTypes.bool.isRequired,
    stickyTopOffset: PropTypes.number,
    stickyActionBarFreezePozition: PropTypes.number,
    stickyGridFreezePozition: PropTypes.number,

    gridType: PropTypes.oneOf(["normal", "selectable", "navigatable", "expandable"]).isRequired,

    isLoading: PropTypes.bool,
    multiselect: PropTypes.bool,
    selectAllCheckbox: PropTypes.bool,
    columns: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        columnGrow: PropTypes.number,
        dataPath: PropTypes.string.isRequired,
        valueDecorator: PropTypes.func,
    })).isRequired,
    data: PropTypes.shape({
        byId: PropTypes.arrayOf(PropTypes.number).isRequired,
        byHash: PropTypes.object.isRequired,
    }).isRequired,
    onItemClick: PropTypes.func,
    onSelectionChanged: PropTypes.func,
    isRowExpandable: PropTypes.func,
    renderExpandedData: PropTypes.func,

    actionGroups: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                icon: PropTypes.string,
                isAvailable: PropTypes.func,
                execute: PropTypes.func.isRequired,
            })
        )
    ).isRequired,
};

GridWithActions.defaultProps = {

    sticky: false,
    gridType: "normal",
};

export default GridWithActions
