import React from "react";
import { Redirect, Route } from 'react-router';
import { KEY_REDIRECT, KEY_ACCESS_TOKEN } from '../../openid/constants';
import { ActivityOverlay } from '../../components';
import { request } from "../../openid/request";
import { prefixUrl } from "../../configuration/config";
import { actions as accountActions } from "../PrivateRoute";
import { actions as extensionManagerActions } from "../ExtensionManager";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isSecurityEnabled } from '../../components/Can';
import { getTranslate } from "react-localize-redux";

const LoginStateConst = {
    PENDING: 1,
    VALID: 2,
    INVALID: 3,
};

class PrivateRoute extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            loginState: LoginStateConst.PENDING,
        }

        this.getAuthenticatedUser = this.getAuthenticatedUser.bind(this);
        this.signinRedirect = this.signinRedirect.bind(this);
        this.renderComponent = this.renderComponent.bind(this);
        this.getUserInfo = this.getUserInfo.bind(this);
        this.userLoaded = this.userLoaded.bind(this);
    }


    async getAuthenticatedUser() {
        const { oidcManager } = this.props;
        return oidcManager.getUser();
    }

    async signinRedirect() {
        window.localStorage.setItem(KEY_REDIRECT, window.location.href);
        const { oidcManager } = this.props;
        await oidcManager.clearStaleState();
        await oidcManager.revokeAccessToken();
        await oidcManager.removeUser();
        await oidcManager.signinRedirect();
    }

    userLoaded() {
        const { oidcManager } = this.props;
        oidcManager.getUser().then(user => {
            window.localStorage.setItem(KEY_ACCESS_TOKEN, user.access_token);
        });
    }

    async getUserInfo() {
        const { accountActions, extensionManagerActions, translate } = this.props;

        const userInfoUrl = [`${prefixUrl}/addon/api/v1/OpenId/userInfo`];
        const response = await request(userInfoUrl);

        if (response.status === 200) {
            const userInfo = await response.json();
            accountActions.setAccount(userInfo);
        } else {
            extensionManagerActions.registerNotificationFailed({
                data: [response],
                failMessage: translate("notificationManager.getUserInfoFailed", { code: response.status })
            });
        }

        this.setState({
            loginState: LoginStateConst.VALID,
        });
    }

    componentDidMount() {
        const { accountActions, oidcManager } = this.props;

        oidcManager.events.addSilentRenewError(this.signinRedirect);
        oidcManager.events.addAccessTokenExpired(this.signinRedirect);
        oidcManager.events.addUserLoaded(this.userLoaded);

        if (isSecurityEnabled()) {
            accountActions.setSecurity(true);
            this.getAuthenticatedUser().then((user) => {
                if (user) {
                    window.localStorage.setItem(KEY_ACCESS_TOKEN, user.access_token);
                    this.getUserInfo();
                } else {
                    this.signinRedirect();
                }
            });
        } else {
            this.getUserInfo();
        }
    }

    componentWillUnmount() {
        const { oidcManager } = this.props;
        oidcManager.events.removeSilentRenewError(this.signinRedirect);
        oidcManager.events.removeAccessTokenExpired(this.signinRedirect);
        oidcManager.events.removeUserLoaded(this.userLoaded);
    }

    renderComponent() {
        const { component: Component, redirect } = this.props;

        switch (this.state.loginState) {
            case LoginStateConst.VALID:
                return <Component />;
            case LoginStateConst.INVALID:
                return <Redirect to={redirect} />;
            default:
                return <ActivityOverlay />;
        }
    }


    render() {
        return (
            <Route path={this.props.path} render={this.renderComponent} />
        );
    }

}

const mapStateToProps = (state) => ({
    translate: getTranslate(state.locale),
})

const mapDispatchToProps = (dispatch) => ({
    accountActions: bindActionCreators(accountActions, dispatch),
    extensionManagerActions: bindActionCreators(extensionManagerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
