const ROLES = {
    VISITOR: 'Tridion.AddonService.ReadOnly',
    CLIENT: 'Tridion.AddonService.Service',
    ADMIN: 'Tridion.AddonService.Administrator',
}

const ACTIONS = {
    addon: {
        list: "addon:list",
        create: "addon:create",
        update: "addon:update",
        delete: "addon:delete",
        metadata: "addon:metadata",
        updateStatus: "addon:updateStatus",
        download: "addon:download",
    },
    config: {
        create: "config:create",
        update: "config:update",
        delete: "config:delete",
        download: "config:download",
    },
    extension: {
        updateStatus: "extension:updateStatus",
    },
    status: {
        reset: "status:reset",
    }
}

const RULES = {
    [ROLES.VISITOR]: [
        ACTIONS.addon.list,
        ACTIONS.addon.metadata,
    ],
    [ROLES.CLIENT]: [
        ACTIONS.addon.list,
        ACTIONS.addon.metadata,
        ACTIONS.addon.updateStatus,
        ACTIONS.addon.download,
        ACTIONS.config.download,
        ACTIONS.extension.updateStatus,
    ],
    [ROLES.ADMIN]: [
        ACTIONS.addon.list,
        ACTIONS.addon.create,
        ACTIONS.addon.update,
        ACTIONS.addon.delete,
        ACTIONS.addon.metadata,
        ACTIONS.addon.updateStatus,
        ACTIONS.addon.download,
        ACTIONS.config.create,
        ACTIONS.config.update,
        ACTIONS.config.delete,
        ACTIONS.config.download,
        ACTIONS.extension.updateStatus,
        ACTIONS.status.reset,
    ]
};

export {
    RULES,
    ROLES,
    ACTIONS
}
