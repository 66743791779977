import React, { PureComponent } from "react";

class NotFoundView extends PureComponent {

    render() {

        return (
            <div>Page Not Found</div>
        );
    }
}

export default NotFoundView
