import browserLocale from "browser-locale"
import moment from "moment";
import "moment/min/locales.js";

const getDefaultLocale = () => {

    // may consider to consume it from the authorization service instead
    return browserLocale();
}

export default function formatDateString(dateString, locale) {

    moment.locale(locale || getDefaultLocale());

    const dateFormat = moment.localeData().longDateFormat("lll");
    const date = moment.utc(dateString).local();
    return date.format(dateFormat);
}
