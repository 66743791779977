import React, { PureComponent } from "react";
import { CSSTransition } from "react-transition-group";
import { Alert } from "../../../../components";
import "./style.css";

class AnimatedNotification extends PureComponent {

    render() {

        return (
            <CSSTransition {...this.props} timeout={200} classNames="animated-notification">
                <Alert {...this.props} />
            </CSSTransition>
        );
    }
}

AnimatedNotification.propTypes = Alert.propTypes;

export default AnimatedNotification
