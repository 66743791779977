import { prefixUrl } from "../../../configuration/config";
import { takeEvery, put, call, select, apply } from "redux-saga/effects";
import { request } from '../../../openid/request';
import { selectors as notificationManagerSelectors } from '../../NotificationManager';
import {
    actions as manageViewActions,
    types as manageViewTypes,
} from "../reducer";

function* saga(action) {

    const {
        configurationFile,
        addonId,
        successMessage,
        failMessage
    } = action.payload;

    const formData = new FormData();
    formData.append("File", configurationFile);

    const fetchParams = [
        `${prefixUrl}/addon/api/v1/addons/${addonId}/configurations`,
        {
            method: "PUT",
            body: formData
        }
    ];

    try {
        const response = yield call(request, ...fetchParams);

        switch (response.status) {
            case 200:
                yield put(manageViewActions.getAddon({ id: addonId }));
                yield put(manageViewActions.registerNotificationSuccess({ successMessage }));
                break;
            case 400:
                const data = yield apply(response, response.json);
                throw data;
            case 401:
            case 403:
                yield put(manageViewActions.getListAddons_failed({
                    data: [response.statusText],
                    failMessage: yield select(state => notificationManagerSelectors.getAuthMessage(state.notificationManager))
                }));
                break;
            default:
                throw response.statusText;
        }

    }
    catch (error) {
        yield put(manageViewActions.registerNotificationFailed({
            failMessage,
            data: [error]
        }));
    }
}

export default function* () {

    yield takeEvery(manageViewTypes.POST_CONFIGURATION, saga);
}
