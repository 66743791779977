import PopupManager from "./PopupManager";
import reducer, { actions, types, selectors } from "./reducer";
import saga from "./saga";

export default PopupManager
export {
    actions,
    types,
    selectors,
    reducer,
    saga,
}
