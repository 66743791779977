import { prefixUrl } from "../../../configuration/config";
import { takeEvery, put, call, apply, select } from "redux-saga/effects";
import { request } from '../../../openid/request';
import { selectors as notificationManagerSelectors } from '../../NotificationManager';
import {
    actions as manageViewActions,
    types as manageViewTypes,
} from "../reducer";

const process = function* (action) {

    try
    {
        const fetchParams = [`${prefixUrl}/addon/api/v1/addons/${action.payload.id}`];

        const response = yield call(request, ...fetchParams);
        const data = yield apply(response, response.json);

        switch (response.status) {
            case 200:
                yield put(manageViewActions.getAddon_done({ data }));
                break;
            case 404:
                yield put(manageViewActions.getAddon_notFound({ data }));
                break;
            case 401:
            case 403:
                yield put(manageViewActions.getListAddons_failed({
                    data: [response.statusText],
                    failMessage: yield select(state => notificationManagerSelectors.getAuthMessage(state.notificationManager)),
                }));
                break;
            default:
                throw data;
        }
    }
    catch (data)
    {
        yield put(manageViewActions.getAddon_failed({ data: [data] }));
    }
}

export default function* () {

    yield takeEvery(manageViewTypes.GET_ADDON, process);
}
