import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import Layout from "./modules/Layout/";
import PrivateRoute from './modules/PrivateRoute';
import LoginCallback from "./modules/LoginCallback/";
import { createOidcUserManager } from "./openid/createOidcUserManager";
import SilentRefresh from "./modules/SilentRefresh/";
import { addonUiUrl } from "./configuration/config"

class App extends React.PureComponent
{
    constructor(props) {
        super(props);
        this.state = {
            oidcManager: createOidcUserManager(),
        }
    }

    render() {
        const oidcManager = this.state.oidcManager;
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path={addonUiUrl + "/signin-oidc"}
                        render={(props) => <LoginCallback {...props} oidcManager={oidcManager} /> }
                    />
                    <Route exact path={addonUiUrl + "/silent-refresh"}
                        render={(props) => <SilentRefresh {...props} oidcManager={oidcManager} /> }
                    />
                    <PrivateRoute path="/" component={Layout} redirect="/home/login" oidcManager={oidcManager} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
